import { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { List, ListSubheader, ListItem, ListItemIcon, ListItemText, Switch } from "@mui/material";

import { Source, Layer, Popup, useMap } from "react-map-gl/maplibre";
import bbox from "@turf/bbox";
import area from "@turf/area";
import intersect from "@turf/intersect";
import circle from "@turf/circle";
import { reduceFeature } from "./Draw";
import FeatureService from "mapbox-gl-arcgis-featureserver";
import apiKey from "../../apikey.json";

const LAYERS = [
    {
        id: "imagery",
        title: "ESRI Imagery"
    },
    { id: "soils", title: "SSURGO Soils" }
],
    CLU_TILES = [`https://fpp-clu-tiles.s3.amazonaws.com/{z}/{y}/{x}.pbf`],
    CLU_FILL_STYLE = {
        id: "clu-fill",
        type: "fill",
        "source-layer": "Common_Land",
        minzoom: 10,
        maxzoom: 20,
        paint: { "fill-color": "#a99", "fill-opacity": 0.2 }
    },
    CLU_LINE_STYLE = {
        id: "clu-line",
        type: "line",
        "source-layer": "Common_Land",
        minzoom: 10,
        maxzoom: 20,
        paint: { "line-color": "#66f", "line-width": 3 }
    },
    CLU_SELECTED_FILL_STYLE = {
        id: "clu_selected_fill",
        type: "fill",
        paint: { "fill-color": "#c99", "fill-opacity": 0.8 }
    },
    CLU_SELECTED_LINE_STYLE = {
        id: "clu_selected_line",
        type: "line",
        paint: { "line-color": "#f95", "line-width": 5 }
    },
    VISIBLE = { visibility: "visible" },
    HIDDEN = { visibility: "none" },
    EMPTY = { type: "FeatureCollection", features: [] },
    SSURGO_SERVICE = "https://landscape11.arcgis.com/arcgis/rest/services/USA_Soils_Map_Units/featureserver/0",
    SSURGO_FILL_STYLE = {
        id: "ssurgo_fill",
        type: "fill",
        minzoom: 12,
        maxzoom: 20,
        paint: {
            "fill-color": "#ff8000",
            "fill-opacity": ["case", ["to-boolean", ["feature-state", "selected"]], 0.6, 0.1]
        }
    },
    SSURGO_LINE_STYLE = {
        id: "ssurgo_line",
        type: "line",
        minzoom: 12,
        maxzoom: 20,
        paint: {
            "line-color": "#ff8000",
            "line-width": ["interpolate", ["linear"], ["zoom"], 12, 1, 15, 3]
        }
    },
    SSURGO_SYMBOL_STYLE = {
        id: "ssurgo_symbol",
        type: "symbol",
        minzoom: 12,
        maxzoom: 20,
        layout: {
            "text-field": "{musym}",
            "text-font": ["Ubuntu Bold"],
            "text-size": 12
        },
        paint: {
            "text-color": "#ff8000",
            "text-halo-color": "black",
            "text-halo-width": 1
        }
    };

export default function LayerList({ layers, toggleLayer, isDisabled }) {
    return (
        <List dense>
            <ListSubheader>Reference Layers</ListSubheader>
            {LAYERS.map(layer => (
                <ListItem key={layer.id} button disabled={isDisabled(layer)} onClick={() => toggleLayer(layer.id)}>
                    <ListItemIcon>
                        <Switch checked={!!layers[layer.id]} />
                    </ListItemIcon>
                    <ListItemText>{layer.title}</ListItemText>
                </ListItem>
            ))}
        </List>
    );
}

export const CLULayer = forwardRef(({ layers, flags, selected, setSelected }, ref) => {
    const { current: map } = useMap();
    const sourecRef = useRef();

    const clearSelectedFeatures = () => {
        setSelected(null);
    };

    useEffect(() => {
        if (!map || !flags.select_clu) {
            return;
        }
        function onMouseMove(evt) {
            const features = map.queryRenderedFeatures(evt.point, { layers: ["clu-line", "clu-fill"] }),
                style = map.getMap()._canvasContainer.style;
            if (features.length > 0) {
                style.cursor = "pointer";
            } else {
                style.cursor = null;
            }
        }
        function onClick(evt) {
            const clicked = reduceFeature(map.queryRenderedFeatures(evt.point, { layers: ["clu-line", "clu-fill"] }));
            if (!clicked) {
                return;
            }
            if (evt.type === "touchstart") {
                evt.preventDefault(); // ensure "click" is fired next
                return;
            }

            const [feature, overlaps] = autoExtend(clicked, map, 5);
            setSelected(selected => {
                let nextSelected = selected ? [...selected.features] : [];
                if (selected && selected.features.some(f => overlaps(f))) {
                    nextSelected = nextSelected.filter(f => !overlaps(f));
                } else {
                    nextSelected.push({ type: "Feature", geometry: feature.geometry });
                }
                if (nextSelected.length > 0) {
                    return { type: "FeatureCollection", features: nextSelected };
                } else {
                    return null;
                }
            });
        }
        map.on("mousemove", onMouseMove);
        map.on("click", onClick);
        map.on("touchstart", onClick);
        return () => {
            map.off("mousemove", onMouseMove);
            map.off("click", onClick);
            map.off("touchstart", onClick);
            const style = map.getMap()._canvasContainer.style;
            style.cursor = null;
        };
    }, [map, flags.select_clu, setSelected]);

    const layout = flags.select_clu ? VISIBLE : HIDDEN;

    useImperativeHandle(ref, () => ({
        clearSelectedFeatures
    }));

    return (
        <>
            <Source id="clu" type="vector" tiles={CLU_TILES} minzoom={10} maxzoom={14}>
                <Layer {...CLU_FILL_STYLE} layout={layout} />
                <Layer {...CLU_LINE_STYLE} layout={layout} />
            </Source>
            <Source id="clu_selected" type="geojson" data={selected || EMPTY} ref={sourecRef}>
                <Layer {...CLU_SELECTED_FILL_STYLE} layout={layout} />
                <Layer {...CLU_SELECTED_LINE_STYLE} layout={layout} />
            </Source>
        </>
    );
});

CLULayer.displayName = 'CLULayer';

class SsurgoService extends FeatureService {
    _getServiceMetadata() {
        return super._getServiceMetadata().then(m => (m.uniqueIdField = { name: "objectid" }));
    }
    _findAndMapData() {
        if (!this._map.getLayer("ssurgo_fill")) {
            return;
        }
        return super._findAndMapData();
    }
    _preload() {
        return super._findAndMapData();
    }
}

export function SoilsLayer({ layers, flags }) {
    const { current: map } = useMap(),
        [service, setService] = useState(null),
        [popup, setPopup] = useState(null),
        closePopup = () => setPopup(null);

    useEffect(() => {
        if (!map) {
            return;
        }
        map.on("idle", updateService);
        function updateService() {
            map.off("idle", updateService);
            setService(service => {
                if (map.getSource("ssurgo")) {
                    return service;
                } else {
                    if (service) {
                        service.disableRequests();
                    }
                    return new SsurgoService("ssurgo", map.getMap(), {
                        url: SSURGO_SERVICE,
                        token: apiKey.agolToken,
                        minZoom: SSURGO_FILL_STYLE.minzoom,
                        useStaticZoomLevel: true,
                        useServiceBounds: false,
                        outFields: "esrisymbology,musym,objectid"
                    });
                }
            });
        }
        return () => map.off("idle", updateService);
    }, [map, layers]);
    useEffect(() => {
        if (!service || !map) {
            return;
        }
        map.on("click", "ssurgo_fill", getInfo);
        map.on("click", "ssurgo_line", getInfo);
        map.on("click", "ssurgo_symbol", getInfo);

        async function getInfo(evt) {
            const feature = evt.features[0];
            if (!feature) {
                return;
            }
            const draw = map.getMap()._controls.find(control => control.getMode);
            if (
                draw &&
                (draw.getMode() !== "simple_select" ||
                    intersect(reduceFeature(draw.getAll()), circle(evt.lngLat.toArray(), 0.01)))
            ) {
                return;
            }
            setPopup({ feature, title: "Loading details...", lngLat: evt.lngLat });

            const data = await service.getFeaturesByObjectIds(feature.id),
                fullFeature = data && data.features && data.features[0];

            if (fullFeature) {
                const { muname, popupstring } = fullFeature.properties;
                setPopup({ feature: fullFeature, title: muname, content: popupstring, lngLat: evt.lngLat });
            } else {
                setPopup({ feature, title: "Error loading details", lngLat: evt.lngLat });
            }
        }

        return () => {
            map.off("click", "ssurgo_fill", getInfo);
            map.off("click", "ssurgo_line", getInfo);
            map.off("click", "ssurgo_symbol", getInfo);
        };
    }, [service, map]);

    useEffect(() => {
        if (!service || !layers.soils) {
            return;
        }
        service._preload();
    }, [service, layers.soils]);

    useEffect(() => {
        if (!map || !map.loaded()) {
            return;
        }
        map.removeFeatureState({ source: "ssurgo" });
        if (popup) {
            map.setFeatureState({ source: "ssurgo", id: popup.feature.id }, { selected: true });
        }
    }, [map, popup]);

    if (!service || !layers.soils || flags.select_clu) {
        return null;
    }

    return (
        <>
            <Layer {...SSURGO_FILL_STYLE} source="ssurgo" beforeId="_placeholder" />
            <Layer {...SSURGO_LINE_STYLE} source="ssurgo" beforeId="_placeholder" />
            <Layer {...SSURGO_SYMBOL_STYLE} source="ssurgo" beforeId="_placeholder" />
            {popup && (
                <Popup longitude={popup.lngLat.lng} latitude={popup.lngLat.lat} maxWidth={null} onClose={closePopup}>
                    <div style={{ width: 360, minHeight: 200 }}>
                        <h3>{popup.title}</h3>
                        <div
                            style={{ height: 200, borderTop: "1px solid #999", overflowY: "auto" }}
                            dangerouslySetInnerHTML={{ __html: popup.content || "Loading..." }}
                        />
                    </div>
                </Popup>
            )}
        </>
    );
}

function autoExtend(feature, map, iterations = 0) {
    const [w, s, e, n] = bbox(feature),
        size = area(feature),
        overlaps = f => {
            const intersection = intersect(feature, f);
            return intersection && area(intersection) / size > 0.01;
        },
        bounds = [map.project([w, s]), map.project([e, n])];

    const extended = reduceFeature(map.queryRenderedFeatures(bounds, { layers: ["clu-fill"] }).filter(overlaps));
    if (iterations > 0) {
        return autoExtend(extended, map, iterations - 1);
    } else {
        return [extended, overlaps];
    }
}
