import React from 'react';

class DateComponent extends React.Component {
    formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    }

    render() {
        const date = this.props.date;
        return <div style={{ color: "#6d8d22" }} >{this.formatDate(date)}</div>;
    }
}
export default DateComponent;