import React from "react";
import { connect } from "react-redux";
import Grid from "../common/GridWrapper";
import { Badge, Button } from "@mui/material";
import Typography from "../common/TypographyWrapper";
import { apiFetch, dbFetch } from "../../api/fetch";
import saveAs from "file-saver";
import { toggleAccordian } from "../common/actions";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import withRouter from "../common/withRouter";
import { CropYear } from "../field/fieldactivities/models";
import { Field } from "../field/models";
import { getValue } from "../../api/utils";
import { Metric } from "../../api/models";
import { Project, ProjectUser } from "../project/models";
import WarningDialog from "../common/WarningDialog";
import ActivityList from "./activities/ActivityList";
import { createSelector } from "../common/orm";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ActionElipse from "../common/ActionElipse";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import cropOptions from "./ftm_crops.json";
import LockIcon from "@mui/icons-material/Lock";
import ToggleBadgeButtonGroup from "../common/ToggleBadgeButtonGroup";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const allProjectUsers = ProjectUser.selectAll(PU => ({
    project_attributes: PU.project,
    ...PU.ref
}));

const allCrops = createSelector(schema => {
    return schema.Crops.all().orderBy("name").toModelArray();
});


const LightButton = ({ children, onClick, ...props }) => {
    return (
        <Button
            variant="text"
            size="small"
            onClickCapture={(event) => {
                event.stopPropagation();
                if (onClick) onClick(event);
            }}
            sx={{
                backgroundColor: "white",
                border: "1px solid #f3f3f4",
                color: "#acaeb0",
                borderRadius: "3px",
                fontSize: "12px",
                padding: "2px 2px",
                "&:hover": {
                    backgroundColor: "#f0f0f0",
                },
            }}
            {...props}
        >
            {children}
        </Button>
    )
};

const metricTitle = { fontSize: "10px", fontWeight: "bold" };
const metricVal = { fontSize: "12px" };
const metricValItem = { fontSize: "12px", fontWeight: "bold", color: "red" };

class CropYearActivitiesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            downloading: false,
            projectPopupOpen: false,
            cropYearPopupOpen: false,
            checked: false,
            projectEnroll: null,
            cropYearView: 0,
            position: {
                top: 0,
                left: 0
            },
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            year: new Date().getFullYear().toString()
        };
    }

    componentDidMount() {
        this.syncCropInfo();
    }

    handleTooltipMove = event => {
        this.setState({
            position: {
                top: event.clientY,
                left: event.clientX
            }
        });
    };

    async download() {
        this.setState({ downloading: true });

        const { authState, cropHistory } = this.props;
        let response;
        if (authState.user && authState.user.guest) {
            response = await apiFetch("/api/v4/calc/CalculatorReport", {
                method: "POST",
                body: JSON.stringify(cropHistory),
                headers: { Accept: "application/pdf" }
            });
        } else {
            response = await dbFetch(`/reports/${cropHistory.id}/pdf`);
        }

        saveAs(await response.blob(), cropHistory.id + ".pdf");

        this.setState({ downloading: false });
    }

    handleRecordStatusChange = () => {
        this.props.ormCropYearUpdate({
            id: this.props.cropHistory.id,
            is_final: !this.props.cropHistory.is_final
        });
    };

    handleCloneCropYear = () => {
        var { cropHistory } = this.props;

        this.props.ormCropYearCreate({
            ...cropHistory
        });
    };

    deleteCropYear = (crop, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: crop,
            deleteDialogText: "Are you sure you wish to permanently delete " + name + "?"
        });
    };

    handleMetricClick = () => {
        this.props.toggleAccordian("METRIC-" + this.props.cropHistory.id);
    };

    handleOperationClick = () => {
        this.props.toggleAccordian("OPERATION-" + this.props.cropHistory.id);
    };

    handlePopupClickOpen = event => {
        event.preventDefault();
        this.setState({ projectPopupOpen: true });
    };

    handleCropYearPopupClickOpen = projid => {
        this.setState({ cropYearPopupOpen: true, projectEnroll: projid, cropYearView: 0 });
    };

    handleRemoveCropYearPopupClickOpen = projid => {
        this.setState({ cropYearPopupOpen: true, projectEnroll: projid, cropYearView: 3 });
    };

    handlePopupClickClose = () => {
        this.setState({ projectPopupOpen: false });
    };

    handleCropYearPopupClickClose = () => {
        this.setState({ cropYearPopupOpen: false, cropYearView: 0 });
    };

    handleEditCropYearClick = (e) => {
        e.stopPropagation();

        var { cropHistory, history } = this.props;

        history.push("/cropyear/" + cropHistory.id);
    };

    handleViewMetrics = () => {
        var { cropHistory, history } = this.props;

        history.push("/cropyear/" + cropHistory.id + "/analysis");
    };

    handleDownloadReportClick = () => {
        this.download();
    };

    handleRunMetrics = () => {
        const { cropHistory, runMetric } = this.props;

        const pending = getValue(cropHistory, "metrics.Calculator.pending");

        if (pending === undefined) {
            runMetric("Calculator", cropHistory.id, true);
        }
    };

    renderNoTillChip(noTill) {
        const contentMap = {
            1: null,
            2: "REDUCE TILLAGE",
            3: "NO TILL",
            default: null
        };

        return contentMap[noTill] || contentMap["default"];
    }

    calcMetrics() {
        const cyMetrics = getValue(this.props.cropHistory, "metrics.Calculator.result.cropyears");

        if (cyMetrics) {
            const cropYr = parseInt(this.props.cropHistory.year);

            const filteredMetrics = cyMetrics.find(item => item.year === cropYr);

            if (filteredMetrics) {
                let metrics = {};

                metrics.biodiversity =
                    filteredMetrics.biodiversity !== undefined
                        ? filteredMetrics.biodiversity.fieldprintResult
                        : { unit: "", value: null };
                metrics.energy =
                    filteredMetrics.energyUse !== undefined
                        ? filteredMetrics.energyUse.fieldprintResult
                        : { unit: "", value: null };
                metrics.greenhouse =
                    filteredMetrics.greenhouseGas !== undefined
                        ? filteredMetrics.greenhouseGas.fieldprintResult
                        : { unit: "", value: null };
                metrics.landuse =
                    filteredMetrics.landUse != undefined
                        ? filteredMetrics.landUse.fieldprintResult
                        : { unit: "", value: null };
                metrics.soilcarbon =
                    filteredMetrics.soilCarbon !== undefined
                        ? filteredMetrics.soilCarbon.fieldprintResult
                        : { unit: "", value: null };
                metrics.soilconservation =
                    filteredMetrics.soilConservation !== undefined
                        ? filteredMetrics.soilConservation.fieldprintResult
                        : { unit: "", value: null };
                metrics.waterquality =
                    filteredMetrics.waterQuality !== undefined
                        ? filteredMetrics.waterQuality.fieldprintResult
                        : { unit: "", value: null };
                metrics.irrigationwateruse =
                    filteredMetrics.irrigationWaterUse !== undefined
                        ? filteredMetrics.irrigationWaterUse.fieldprintResult
                        : { unit: "", value: null };

                return metrics;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    syncCropInfo() {
        var { syncState, cropHistory, ormFieldLoadDetail, ormCropYearLoadDetail } = this.props;

        if (syncState.ready && !getValue(cropHistory.fieldObject, "synced") && cropHistory.fieldObject) {
            ormFieldLoadDetail(cropHistory.fieldObject.id);
        }
        if (syncState.ready && !getValue(cropHistory, "synced")) {
            ormCropYearLoadDetail(cropHistory.id);
        }
    }

    parseProjectCrops() {
        var { projects, cropHistory } = this.props;

        let availProject = [];

        // Don't show crop years that aren't applicable
        projects.map(({ project_attributes }) => {
            // No Future Crop Years
            if (cropHistory.year <= new Date().getFullYear()) {
                // Must have crop related to project
                if (project_attributes.crops_label.includes(cropHistory.crop_name)) {
                    // Not related to a different project
                    if (cropHistory.project_instance === null || cropHistory.project_instance === undefined) {
                        availProject.push({
                            id: project_attributes.id,
                            name: project_attributes.name,
                            existing: false,
                            validCrop: true
                        });
                    } else if (cropHistory.project_instance === project_attributes.id) {
                        availProject.push({
                            id: project_attributes.id,
                            name: project_attributes.name,
                            existing: true,
                            validCrop: true
                        });
                    }
                } else {
                    availProject.push({ id: null, name: null, existing: null, validCrop: false });
                }
            }
        });

        // if only one project, return that
        if (availProject.length === 1) {
            return availProject[0];
        } else {
            const hasExistingCrop = availProject.some(item => item.existing === true);
            const hasValidCrop = availProject.some(item => item.validCrop === true);
            if (hasExistingCrop) {
                // if multiple projects, test if there is already one assigned to the cy and return that
                return availProject.find(item => item.existing === true);
            } else if (hasValidCrop) {
                // else return the first valid project for the cy type
                return availProject.find(item => item.validCrop === true);
            } else {
                // else return the null value object
                return availProject.find(item => item.validCrop === false);
            }
        }
    }

    getLast25Years() {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let i = 0; i < 25; i++) {
            years.push(currentYear - i);
        }

        return years;
    }

    handleButtonClick = (event) => {
        event.stopPropagation();
    };

    handleAutoCompleteChange = (event, value) => {
        event.stopPropagation();
    };

    getActivities(cropHistory) {

        let activities = [];

        if (cropHistory && cropHistory.crop_name) {

            if (cropHistory.planting_date) {
                activities.push({ crop: cropHistory.crop_name, date: cropHistory.planting_date, type: "plantingcash" });
            }

            if (cropHistory.harvesting_date) {
                activities.push({ crop: cropHistory.crop_name, date: cropHistory.harvesting_date, type: "harvest" });
            }
        }

        return activities;
    }

    render() {
        var { cropHistory, ormCropYearDelete } = this.props;
        const { deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction } = this.state;

        let percentComplete = 0;

        const error = getValue(cropHistory, "metrics.Calculator.error"),
            pending = getValue(cropHistory, "metrics.Calculator.pending"),
            percentMetricsComplete = getValue(cropHistory, "metrics.Calculator.percentComplete"),
            completeStatus = getValue(cropHistory, "metrics.Calculator.completeStatus");


        const metrics = this.calcMetrics();

        //const projEnrol = this.parseProjectCrops();

        const last25Yrs = this.getLast25Years();

        const activities = this.getActivities(cropHistory);

        if (cropHistory.extrainfo != null) {
            const m = cropHistory.extrainfo.missing;
            const r = cropHistory.extrainfo.required;
            if (r) {
                var missing =
                    (m[0] || m[0] === 0 ? m[0] : 5) +
                    (m[1] || m[1] === 0 ? m[1] : 10) +
                    (m[2] || m[2] === 0 ? m[2] : 3);
                var required =
                    (r[0] || r[0] === 0 ? r[0] : 5) +
                    (r[1] || r[1] === 0 ? r[1] : 10) +
                    (r[2] || r[2] === 0 ? r[2] : 3);
                percentComplete = (((required - missing) / required) * 100).toFixed(0);
            }
        }

        return (
            <Grid
                key={cropHistory.id}
                container
                maxWidth="lg"
                style={{ marginTop: "25px", borderTop: "14px solid #212121", borderLeft: "1px solid #d9dbdc", borderRight: "1px solid #d9dbdc", borderBottom: "1px solid #d9dbdc", borderRadius: "6px" }}>

                <Accordion sx={{ border: 'none', borderTop: "solid 1px #e4e5e6", boxShadow: 'none', width: "100%", backgroundColor: "#fafafa" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container xs={11} spacing={2}>
                            <Grid container item xl={3} lg={4} sm={4} xs={12} spacing={2} style={{ marginTop: "5px" }}>
                                <Grid item>
                                    <Typography variant="title" style={{ fontWeight: "600", color: "#5f6368", marginTop: "5px", marginRight: "5px", marginLeft: "5px" }}>YEAR:</Typography>
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={last25Yrs}
                                        style={{ marginLeft: "5px" }}
                                        getOptionLabel={(option) => option}
                                        onChange={this.handleAutoCompleteChange}
                                        value={cropHistory.year}
                                        disabled={true}
                                        sx={{
                                            minWidth: "120px",
                                            borderRadius: 0,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: 0,
                                                "& fieldset": {
                                                    borderRadius: 0,
                                                },
                                                "& .MuiSelect-select": {
                                                    textAlign: "center",
                                                    border: "1px solid #f15d22",
                                                    borderRadius: 0,
                                                },
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref} style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>
                                                <input
                                                    type="text"
                                                    {...params.inputProps}
                                                    placeholder="Select Year"
                                                    style={{
                                                        width: "100px",
                                                        padding: "10px",
                                                        borderRadius: "0px",
                                                        border: "1px solid #c4c4c4",
                                                        boxSizing: "border-box",
                                                        color: "#c4c4c4",
                                                        outline: params.inputProps['aria-expanded'] ? '2px solid #f15d22' : 'none'
                                                    }}
                                                    onClick={this.handleButtonClick}
                                                />
                                                <KeyboardArrowDownIcon style={{ position: 'absolute', right: '10px', pointerEvents: 'none' }} />
                                            </div>

                                        )}
                                    />

                                </Grid>
                            </Grid>
                            <Grid container item xl={4} lg={5} sm={7} xs={12} spacing={2} justifyContent="flex-start" style={{ marginTop: "5px" }}>
                                <Grid item>
                                    <Typography variant="title" style={{ fontWeight: "600", color: "#5f6368", marginTop: "5px", marginLeft: "11px" }}>CROP</Typography>
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={cropOptions}
                                        style={{ marginLeft: "5px" }}
                                        groupBy={(option) => option.category}
                                        getOptionLabel={(option) => option.crop}
                                        onChange={this.handleAutoCompleteChange}
                                        value={cropOptions.find((opt) => opt.crop === cropHistory.crop_name)}
                                        disabled={true}
                                        sx={{
                                            minWidth: "230px",
                                            borderRadius: 0,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: 0,
                                                "& fieldset": {
                                                    borderRadius: 0,
                                                },
                                                "& .MuiSelect-select": {
                                                    textAlign: "center",
                                                    border: "1px solid #f15d22",
                                                    borderRadius: 0,
                                                },
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref} style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>
                                                <input
                                                    type="text"
                                                    {...params.inputProps}
                                                    placeholder="Select Crop"
                                                    style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        borderRadius: "0px",
                                                        border: "1px solid #c4c4c4",
                                                        boxSizing: "border-box",
                                                        color: "#c4c4c4",
                                                        outline: params.inputProps['aria-expanded'] ? '2px solid #f15d22' : 'none'
                                                    }}
                                                    onClick={this.handleButtonClick}
                                                />
                                                <KeyboardArrowDownIcon style={{ position: 'absolute', right: '40px', pointerEvents: 'none' }} />
                                                <LockIcon style={{ marginLeft: "5px", color: "grey" }} />
                                            </div>

                                        )}

                                    />

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container lg={1} sm={1} xs={1}>
                            <ActionElipse style={{ marginLeft: 8 }} actions={[
                                { name: "Edit Crop Year", onClick: this.handleEditCropYearClick },
                                {
                                    name:
                                        error != undefined
                                            ? "Error Calculating Report"
                                            : "Calculate Report",
                                    onClick: this.handleRunMetrics,
                                    disabled:
                                        percentComplete === "100" && cropHistory.metrics_ran === false
                                            ? false
                                            : true,
                                    isLoading: pending,
                                    loadingMessage:
                                        (percentMetricsComplete !== "null" ? percentMetricsComplete : "0") +
                                        "% Complete: " +
                                        (completeStatus !== "null" ? completeStatus : "Loading...")
                                },
                                {
                                    name: "View Metrics",
                                    onClick: this.handleViewMetrics,
                                    disabled:
                                        percentComplete === "100" && cropHistory.metrics_ran === true
                                            ? false
                                            : true
                                },
                                {
                                    name: "Download Report",
                                    onClick: this.handleDownloadReportClick,
                                    disabled:
                                        cropHistory.metrics_ran === true && !this.state.downloading
                                            ? false
                                            : true,
                                    isLoading: this.state.downloading
                                }
                            ]} />
                        </Grid>

                    </AccordionSummary>
                    <AccordionDetails sx={{ border: 'none', borderTop: "solid 1px #e4e5e6", boxShadow: 'none', backgroundColor: "#fafafa" }} >
                        <Grid container xs={12}>
                            <Grid container md={2} sm={6} style={{ marginTop: "5px" }}>
                                <Typography style={{ fontSize: "16px", fontWeight: "600", marginTop: "3px" }}>Data Entry: </Typography>
                                <Grid item>
                                    <Badge
                                        color="secondary"
                                        style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                color: "#ffffff",
                                                backgroundColor: "#6d8d22",
                                                padding: "6px",
                                                marginBottom: "3px",
                                                borderRadius: "3px"
                                            }}>
                                            COMPLETE
                                        </Typography>
                                    </Badge>
                                </Grid>
                            </Grid>
                            <Grid container md={3} sm={6} style={{ marginTop: "5px" }}>
                                <Typography style={{ fontSize: "16px", fontWeight: "600", marginTop: "3px" }}>Data Status: </Typography>
                                <Grid>
                                    <ToggleBadgeButtonGroup trueText={"PROVISIONAL"} falseText={"FINALIZED"} />
                                </Grid>
                            </Grid>
                            <Grid container md={3} sm={6} style={{ marginTop: "5px" }}>
                                <Typography style={{ fontSize: "16px", fontWeight: "600", marginTop: "3px" }}>Metrics: </Typography>
                                <Grid>
                                    <ToggleBadgeButtonGroup trueText={"NOT CALCULATED"} falseText={"CALCULATE"} />
                                </Grid>
                            </Grid>
                            <Grid container md={4} sm={6} style={{ marginTop: "5px" }}>
                                <Typography style={{ fontSize: "16px", fontWeight: "600", marginTop: "4px" }}>Project Enrollment: </Typography>
                                <Grid item>
                                    <Autocomplete
                                        options={cropOptions}
                                        style={{ marginLeft: "5px" }}
                                        groupBy={(option) => option.category}
                                        getOptionLabel={(option) => option.crop}
                                        onChange={this.handleAutoCompleteChange}
                                        sx={{
                                            minWidth: "160px",
                                            borderRadius: 0,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: 0,
                                                "& fieldset": {
                                                    borderRadius: 0,
                                                },
                                                "& .MuiSelect-select": {
                                                    textAlign: "center",
                                                    border: "1px solid #f15d22",
                                                    borderRadius: 0,
                                                },
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref} style={{ display: 'flex', alignItems: 'center', width: '180px', position: 'relative' }}>
                                                <input
                                                    type="text"
                                                    {...params.inputProps}
                                                    placeholder="Select Project Name"
                                                    style={{
                                                        width: "160px",
                                                        padding: "10px",
                                                        borderRadius: "0px",
                                                        border: "1px solid #c4c4c4",
                                                        boxSizing: "border-box",
                                                        color: "#c4c4c4",
                                                        outline: params.inputProps['aria-expanded'] ? '2px solid #f15d22' : 'none'
                                                    }}
                                                    onClick={this.handleButtonClick}
                                                />
                                                <KeyboardArrowDownIcon style={{ position: 'absolute', right: '10px', pointerEvents: 'none' }} />
                                            </div>

                                        )}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Box sx={{ width: "100%", marginTop: "20px" }}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="fieldprint-analysis"
                                        id="fieldprint-header"
                                        style={{ width: "100%" }}
                                    >
                                        <Typography style={{ fontSize: "16px", fontWeight: "bold", marginTop: "2px" }}>Fieldprint Analysis</Typography>
                                        <Box sx={{ display: "flex", gap: 1, marginLeft: "20px" }}>
                                            <LightButton>
                                                View Full Analysis
                                            </LightButton>
                                            <LightButton>
                                                PDF Report
                                            </LightButton>
                                        </Box>
                                        <Box sx={{ marginLeft: "auto", marginRight: "5px", paddingLeft: "5px" }}>
                                            <LightButton >
                                                Recalculate
                                            </LightButton>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container xs={12}>
                                            <Grid md={6} sm={12} xs={12} style={{ minHeight: "150px" }}>
                                                SPIDERGRAM
                                            </Grid>
                                            <Grid md={6} sm={12} xs={12} style={{ marginTop: "10px" }}>
                                                <Grid container xs={12}>
                                                    <Grid xs={4}>
                                                        <Typography style={metricTitle}>BIODIVERSITY</Typography>
                                                        <Typography style={metricVal}>
                                                            {metrics && metrics.biodiversity && metrics.biodiversity.value !== null ? (
                                                                <span style={metricValItem}>
                                                                    {new Intl.NumberFormat("en-US", {
                                                                        style: "percent",
                                                                        minimumIntegerDigits: 1
                                                                    }).format(metrics.biodiversity.value)}
                                                                </span>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={4}>
                                                        <Typography style={metricTitle}>ENERGY</Typography>
                                                        <Typography style={metricVal}>
                                                            {metrics && metrics.energy && metrics.energy.value !== null ? (
                                                                <>
                                                                    <span style={metricValItem}>
                                                                        {new Intl.NumberFormat().format(
                                                                            Math.round(metrics.energy.value)
                                                                        )}
                                                                    </span>{" "}
                                                                    {metrics.energy.unit}
                                                                </>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={4}>
                                                        <Typography style={metricTitle}>GREENHOUSE GAS</Typography>
                                                        <Typography style={metricVal}>
                                                            {metrics && metrics.greenhouse && metrics.greenhouse.value !== null ? (
                                                                <>
                                                                    <span style={metricValItem}>
                                                                        {new Intl.NumberFormat().format(
                                                                            Math.round(metrics.greenhouse.value)
                                                                        )}
                                                                    </span>{" "}
                                                                    {metrics.greenhouse.unit}
                                                                </>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12}>
                                                    <Grid xs={4}>
                                                        <Typography style={metricTitle}>IRRIGATED WATER USE</Typography>
                                                        <Typography style={metricVal}>
                                                            {metrics && metrics.irrigationwateruse && metrics.irrigationwateruse.value !== null ? (
                                                                <>
                                                                    <span style={metricValItem}>
                                                                        {new Intl.NumberFormat().format(
                                                                            metrics.irrigationwateruse.value
                                                                        )}
                                                                    </span>{" "}
                                                                    {metrics.irrigationwateruse.unit}
                                                                </>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={4}>
                                                        <Typography style={metricTitle}>LAND USE</Typography>
                                                        <Typography style={metricVal}>
                                                            {metrics && metrics.landuse && metrics.landuse.value !== null ? (
                                                                <>
                                                                    <span style={metricValItem}>
                                                                        {new Intl.NumberFormat().format(metrics.landuse.value)}
                                                                    </span>{" "}
                                                                    {metrics.landuse.unit}
                                                                </>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={4}>
                                                        <Typography style={metricTitle}>SOIL CARBON</Typography>
                                                        <Typography style={metricVal}>
                                                            {metrics && metrics.soilcarbon && metrics.soilcarbon.value !== null ? (
                                                                <>
                                                                    <span style={metricValItem}>
                                                                        {new Intl.NumberFormat().format(metrics.soilcarbon.value)}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12}>
                                                    <Grid xs={4}>
                                                        <Typography style={metricTitle}>SOIL CONSERVATION</Typography>
                                                        <Typography style={metricVal}>
                                                            {metrics && metrics.soilconservation && metrics.soilconservation.value !== null ? (
                                                                <>
                                                                    <span style={metricValItem}>
                                                                        {new Intl.NumberFormat().format(metrics.soilconservation.value)}
                                                                    </span>{" "}
                                                                    {metrics.soilconservation.unit}
                                                                </>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={4}>
                                                        <Typography style={metricTitle}>WATER QUALITY</Typography>
                                                        <Typography style={metricVal}>
                                                            {metrics && metrics.waterquality && metrics.waterquality.value !== null ? (
                                                                <>
                                                                    <span style={metricValItem}>{metrics.waterquality.value}/4</span>{" "}
                                                                    pathways
                                                                </>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={4}>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Grid>
                        <Grid container xs={12}>
                            <Box sx={{ width: "100%", marginTop: "20px" }}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="fieldprint-analysis"
                                        id="fieldprint-header"
                                        style={{ width: "100%" }}
                                    >
                                        <Grid container xs={12}>
                                            <Grid item xs={12}>
                                                <Typography style={{ fontSize: "16px", fontWeight: "bold", marginTop: "2px", marginBottom: "2px" }}>Field-Level Defaults</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{ fontSize: "12px", marginBottom: "2px" }}>Modify field-level defaults completed during field creation. These values should be checked for each crop planted.</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="text"
                                                    size="small"
                                                    onClick={this.handleButtonClick}
                                                    sx={{
                                                        backgroundColor: "#f9c753",
                                                        border: "none",
                                                        color: "#645021",
                                                        borderRadius: "3px",
                                                        fontSize: "12px",
                                                        "&:hover": {
                                                            backgroundColor: "#fab003",
                                                        },
                                                    }}
                                                    startIcon={<ReportProblemIcon style={{ marginLeft: "3px" }} />}
                                                >
                                                    NEEDS REVIEW
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                </Accordion>
                            </Box>
                        </Grid>
                        <Grid container xs={12}>
                            <ActivityList data={activities} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <WarningDialog
                    confirmAction={() => {
                        ormCropYearDelete(deleteDialogConfirmAction);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Crop Year"
                    text={deleteDialogText}
                />
            </Grid>

        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isMetricExpanded:
        state.nav.dashboardAccordian != null
            ? state.nav.dashboardAccordian["METRIC-" + ownProps.cropHistory.id]
            : false || false,
    isOperationExpanded:
        state.nav.dashboardAccordian != null
            ? state.nav.dashboardAccordian["OPERATION-" + ownProps.cropHistory.id]
            : false || false,
    projects: allProjectUsers(state),
    crops: allCrops(state)
});
const mapDispatchToProps = {
    toggleAccordian,
    ...CropYear.actions,
    ...Metric.actions,
    ...Field.actions,
    ...Project.actions,
    ...ProjectUser.actions
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CropYearActivitiesComponent));
