import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import withRouter from "../common/withRouter";
import ActionElipse from "../common/ActionElipse";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";
import * as navActions from "../common/actions";
import FarmNameDialog from "../dashboard/FarmNameDialog";
import WarningDialog from "../common/WarningDialog";
import { Farm } from "../field/models";

const styles = {
    header: {
        backgroundColor: "#415058",
        paddingTop: 14,
        paddingLeft: 19,
        paddingBottom: 10
    },
    headerTitle: {
        color: "#ffffff",
        fontWeight: "600",
        fontSize: "20px"
    },
    fieldTitles: {
        fontWeight: "bold",
        color: "#d9d9d9",
        marginTop: "10px"
    },
    fieldVals: {
        fontSize: "11px",
        color: "#ffffff"
    }

};

class FarmHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            renameFarmOpen: false,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
        };
    }

    handleRenamePopupClickClose = () => {
        this.setState({ renameFarmOpen: false });
    }

    navigateField = farmId => {
        this.props.navSelectPage("field_new_" + farmId);
        this.props.history.push(`/farm/${farmId}/addfield`);
    };

    deleteFarm = (farm, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: farm,
            deleteDialogText: (
                <div>
                    Are you sure you wish to permanently delete {name}?<br />
                    <br />
                    Please note that deleting your farm deletes all associated fields and crop years and all associated
                    data. This information cannot be recovered. If any crop years are associated with Projects, those
                    associations will also be deleted. In addition, any SAI Platform FSA Equivalency Module surveys
                    completed will be deleted. If you are connected with a Project you may want to confirm with the
                    Project Administrator prior to deleting your farm and fields. <br />
                    <br />
                    Are you sure you wish to permanently delete this farm and all associated data?
                </div>
            )
        });
    };

    doDeleteFarm = farm => {
        this.props.ormFarmDelete(farm);
        this.setState({ deleteDialogOpen: false });
        this.props.navSelectPage("dashboard");
        this.props.history.push("/dashboard");
    };

    render() {
        const { classes, title, color, farmStats } = this.props;
        const { deleteDialogConfirmAction, deleteDialogOpen, deleteDialogText } = this.state;

        return (
            <Grid container className={classNames(classes.header, classes[color])} style={{ overflowX: "hidden" }}>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography className={classNames(classes.headerTitle)}>{title}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <ActionElipse actions={[
                            { name: "Rename Farm", onClick: () => this.setState({ renameFarmOpen: true }) },
                            { name: "Add Field", onClick: () => this.navigateField(farmStats.id) },
                            { name: "Delete Farm", onClick: () => this.deleteFarm(farmStats.id, farmStats.name || farmStats.id) },
                        ]} />
                    </Grid>
                </Grid>
                <Grid container justify="space-between" className={classNames(classes.fieldVals)}>
                    <Grid item sm={3} xs={6}>
                        <Grid className={classNames(classes.fieldTitles)}>
                            STATE
                        </Grid>
                        <Grid>
                            {farmStats.State}
                        </Grid>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Grid className={classNames(classes.fieldTitles)}>
                            COUNTY
                        </Grid>
                        <Grid>
                            {farmStats.County}
                        </Grid>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Grid className={classNames(classes.fieldTitles)}>
                            FIELDS ENTERED
                        </Grid>
                        <Grid>
                            {farmStats.FieldCt}
                        </Grid>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Grid className={classNames(classes.fieldTitles)}>
                            TOTAL ACRES ENTERED
                        </Grid>
                        <Grid>
                            {farmStats.FieldAcres}
                        </Grid>
                    </Grid>
                </Grid>
                <FarmNameDialog open={this.state.renameFarmOpen} farm={farmStats} handlePopupClickClose={this.handleRenamePopupClickClose} />
                <WarningDialog
                    confirmAction={() => this.doDeleteFarm(deleteDialogConfirmAction)}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Farm"
                    confirmText="Delete Farm"
                    text={deleteDialogText}
                />
            </Grid>
        )
    }
}

FarmHeader = connect(
    (state) => ({
        navState: state.nav
    }),
    {
        ...Farm.actions,
        ...navActions,
    }
)(FarmHeader);


export default withStyles(styles)(withRouter(FarmHeader));
