import { useState } from "react";
import ActivityDetail from "../ActivityDetail.jsx";
import { QuestionMark } from "@mui/icons-material";
import { Grid, TextField, Typography } from "@mui/material";

export default function CropProtectantSpraying() {
    const [values, setValues] = useState({ yield: 0 });
    const handleSubmit = data => {
        console.log(data);
        setValues(data);
    };

    return (
        <ActivityDetail
            values={values}
            icon={<QuestionMark />}
            type="Crop Protectant / Spraying"
            summary={
                <>
                    <Grid item sm={4} xs={12}>
                        <Typography style={{ fontSize: "14px" }}>A VALUE</Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>{values.yield} amt</Typography>
                    </Grid>
                </>
            }
            onSubmit={handleSubmit}>
            <Grid container paddingTop={2} spacing={1}>
                <Grid item md={7} sm={7} xs={12}>
                    <Typography>Activity Date</Typography>
                    <TextField
                        field="date"
                        type="date"
                        size="small"
                        sx={{ width: "180px" }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item md={5} sm={5} xs={12}>
                    <Typography>A Value</Typography>
                    <TextField
                        size="small"
                        fullWidth
                        field="yield"
                        units="units"
                        type="number"
                        inputProps={{
                            step: 1
                        }}
                        sx={{ width: "140px" }}
                    />
                </Grid>
            </Grid>
        </ActivityDetail>
    );
}
