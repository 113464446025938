import { InputAdornment, TextField as MuiTextField } from "@mui/material";
import { FormField } from "react-form";

function TextField({ fieldApi, units, ...rest }) {
    const { setValue, getValue } = fieldApi;

    const InputProps = units ? { endAdornment: <InputAdornment position="end">{units}</InputAdornment> } : {};

    return (
        <MuiTextField InputProps={InputProps} value={getValue()} onChange={e => setValue(e.target.value)} {...rest} />
    );
}

export default FormField(TextField);
