import React, { useEffect } from "react";
import classNames from "classnames";

import Grid from "./GridWrapper";
import Typography from "./TypographyWrapper";
import withStyles from "@mui/styles/withStyles";

const styles = {
    header: {
        backgroundColor: "#eeeeee",
        paddingTop: 24,
        paddingLeft: 29,
        paddingBottom: 20
    },
    betadashboard: {
        borderBottom: "1px solid #b9b9b9",
        backgroundColor: "#f0f2e5",
        "& h1": {
            fontWeight: "600"
        },
    },
    dashboard: {
        borderBottom: "3px solid #00adee"
    },
    field: {
        borderBottom: "3px solid #34a853"
    },
    project: {
        borderBottom: "3px solid #ff7d32"
    },
    crop: {
        borderBottom: "3px solid #7ac143"
    },
    research: {
        borderBottom: "3px solid #72bb53"
    },
    scenario: {
        borderBottom: "3px solid #8646fd"
    },
    support: {
        borderBottom: "3px solid #aaaaaa"
    },
    welcome: {
        borderBottom: "3px solid #edb13c"
    },
    fontSize: {
        fontSize: 12.5
    },
    marginTop: {
        marginTop: -18,
        marginBottom: -12
    }
};

function PageHeader({ classes, title, pageTitle, color, crumbs }) {
    useEffect(() => {
        let docTitle;
        if (pageTitle) {
            docTitle = pageTitle;
        } else if (typeof title === "string") {
            docTitle = title;
        }
        if (docTitle) {
            document.title = `${docTitle} - Fieldprint Platform`;
        } else {
            document.title = `Fieldprint Platform`;
        }
    }, [title, pageTitle]);

    return (
        <div className={classNames(classes.header, classes[color])}>
            {!crumbs && <Typography variant="display1">{title}</Typography>}
            {crumbs && (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="display1">{title}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography variant="caption" className={classes.fontSize}>
                            {crumbs}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default withStyles(styles)(PageHeader);
