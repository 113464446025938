import React, { Component } from "react";
import { Form } from "react-form";
import withRouter from "../common/withRouter";
import { connect } from "react-redux";
import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";
import { CropYear, FieldActivity } from "./fieldactivities/models";
import { FarmCropYear } from "./models";
import { Fertilizer, ActivityIrrigationWaterUseEvent } from "./fieldactivities/activities/models";
import { createSelector } from "../common/orm";
import { RICE, NOT_DRIED_OR_CUSTOM_DRIED, BEANS } from "../../api/constants";
import { getValue, setValue } from "../../api/utils";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Autocomplete from "@mui/material/Autocomplete";
import cropOptions from "../dashboard/ftm_crops.json";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const DRYING_INPUTS = [
    "harvest.energyuse.drying_system",
    "harvest.energyuse.drying_use_actual",
    "harvest.energyuse.drying_energy_source",
    "harvest.energyuse.drying_electricity_amount",
    "harvest.energyuse.drying_fuel_id",
    "harvest.energyuse.drying_fuel_amount",
    "harvest.energyuse.moisture_removed"
];

const allFarms = createSelector(schema => {
    return schema.Farm.all().orderBy("name").toModelArray();
});

const allFields = createSelector(schema => {
    return schema.Field.all().orderBy("name").toModelArray();
});

const allCrops = createSelector(schema => {
    return schema.Crops.all().orderBy("name").toModelArray();
});
const allDryBeansVarieties = createSelector(schema => {
    return schema.DryBeansVarieties.all().orderBy("name").toModelArray();
});
const getFarmCropYear = createSelector(
    (state, ownProps) => ownProps.farm_id,
    (session, farm_id) => {
        return session.FarmCropYear.filter({ farm: farm_id }).toModelArray();
    }
);

const getCropYears = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    session => {
        return session.CropYear.orderBy("year", "desc")
            .toModelArray()
            .map(cropYear => ({
                crop_name: cropYear.crop.name,
                field_name: cropYear.field.name,
                farm_name: cropYear.field.farm.name,
                activitiesLoad: cropYear.activities.all().toModelArray(),
                _activities: cropYear.activities
                    .all()
                    .toModelArray()
                    .map(activity => {
                        const activityFert = activity.fertilizers.count();
                        const activityWaterUse = activity.wateruseevent.count();
                        return {
                            _fertilizers: activityFert > 0 && activity.fertilizers.all().toRefArray(),
                            _wateruseevent: activityWaterUse > 0 && activity.wateruseevent.all().toRefArray(),
                            ...activity.ref
                        };
                    }),
                ...cropYear.ref
            }));
    }
);

const styles = {
    dialogMin: {
        minWidth: "400px"
    },
    flex: {
        flex: 1
    },
    button: {
        float: "right"
    },
    gutterTop: {
        marginTop: ".6em"
    },
    minWidth: {
        minWidth: 600
    }
};



class AddCropYearInline extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            previousYear: "empty",
            previousFarm: props.farm_id,
            previousField: props.fieldId,
            showFarmCropYear: false,
            showCopyFields: false,
            crop: null,
            year: null,
            farmCropYearUpdate: null,
            isMdUp: false
        };
    }


    loadCropYear(cropYear) {
        const { ormCropYearLoadDetail, ormFieldActivityLoadDetail, ormFertilizerLoadDetail } = this.props;

        if (!getValue(cropYear, "synced")) {
            ormCropYearLoadDetail(cropYear.id);

            cropYear.activitiesLoad.forEach(function (activity) {
                ormFieldActivityLoadDetail(activity.id);

                activity.fertilizers.toModelArray().forEach(function (fertilizer) {
                    ormFertilizerLoadDetail(fertilizer.id);
                });
            });
        }
    }

    addCropYear(values) {
        const {
            cropYears,
            ormCropYearCreate,
            fieldId,
            ormFieldActivityCreate,
            returnDialog,
            farm_id,
            ormFarmCropYearCreate,
            ormFarmCropYearUpdate,
            ormFertilizerCreate,
            ormActivityIrrigationWaterUseEventCreateRemoteFirst
        } = this.props;
        var cropYearId;

        // Save the managed acre record if applicable
        const { showFarmCropYear, farmCropYearUpdate } = this.state;
        if (showFarmCropYear) {
            if (!farmCropYearUpdate) {
                ormFarmCropYearCreate({
                    farm: farm_id,
                    year: values.year,
                    crop: values.primary,
                    irrigated: values.irrigated,
                    nonirrigated: values.nonirrigated
                });
            } else {
                ormFarmCropYearUpdate({
                    id: farmCropYearUpdate,
                    farm: farm_id,
                    year: values.year,
                    crop: values.primary,
                    irrigated: values.irrigated,
                    nonirrigated: values.nonirrigated
                });
            }
            this.setState({ showFarmCropYear: false });
        }

        // Use values from a previous year
        if (values.defaultyear && values.defaultyear !== "empty") {
            const pY = cropYears.filter(cY => cY.id === values.defaultyear)[0];
            const pYInsert = {};

            //setValue(pYInsert, "extrainfo.completed", getValue(pY, "extrainfo.completed"));
            //setValue(pYInsert, "extrainfo.missing", getValue(pY, "extrainfo.missing"));
            //setValue(pYInsert, "extrainfo.required", getValue(pY, "extrainfo.required"));
            if (values.defaults && values.defaults.includes("rotation")) {
                // Copy the step 1 values
                setValue(pYInsert, "biodiversity.rotation_practice", getValue(pY, "biodiversity.rotation_practice"));
                setValue(pYInsert, "rotation", getValue(pY, "rotation"));
                setValue(pYInsert, "greenhouse.residue_burning", getValue(pY, "greenhouse.residue_burning"));
                setValue(pYInsert, "biodiversity.cover_crop", getValue(pY, "biodiversity.cover_crop"));
                setValue(pYInsert, "waterquality.cover_crop_species", getValue(pY, "waterquality.cover_crop_species"));
                setValue(
                    pYInsert,
                    "waterquality.cover_crop_establish_timing",
                    getValue(pY, "waterquality.cover_crop_establish_timing")
                );
                setValue(pYInsert, "biodiversity.tillage_class", getValue(pY, "biodiversity.tillage_class"));
                setValue(pYInsert, "greenhouse.previous_crop_id", getValue(pY, "greenhouse.previous_crop_id"));
                setValue(pYInsert, "greenhouse.yield_previous", getValue(pY, "greenhouse.yield_previous"));
            }

            if (values.defaults && values.defaults.includes("management")) {
                // Copy the step 2 values
                setValue(pYInsert, "is_irrigated", getValue(pY, "is_irrigated"));
                setValue(pYInsert, "soils.has_wind_barriers", getValue(pY, "soils.has_wind_barriers"));
                setValue(pYInsert, "waterquality.tile_drainage", getValue(pY, "waterquality.tile_drainage"));
                setValue(pYInsert, "greenhouse.water_regime", getValue(pY, "greenhouse.water_regime"));
                setValue(
                    pYInsert,
                    "biodiversity.nutrient_management_plan",
                    getValue(pY, "biodiversity.nutrient_management_plan")
                );
                setValue(pYInsert, "energyuse.lime_applied", getValue(pY, "energyuse.lime_applied"));
                setValue(pYInsert, "energyuse.lime_year_applied", getValue(pY, "energyuse.lime_year_applied"));
                setValue(pYInsert, "energyuse.lime_years", getValue(pY, "energyuse.lime_years"));
                setValue(pYInsert, "energyuse.lime_amount", getValue(pY, "energyuse.lime_amount"));
                setValue(pYInsert, "greenhouse.has_sulfur", getValue(pY, "greenhouse.has_sulfur"));
                setValue(pYInsert, "greenhouse.sulfur_rate", getValue(pY, "greenhouse.sulfur_rate"));
                setValue(pYInsert, "waterquality.nutrient_applied", getValue(pY, "waterquality.nutrient_applied"));
                setValue(pYInsert, "waterquality.nitrogen_applied", getValue(pY, "waterquality.nitrogen_applied"));
                setValue(pYInsert, "waterquality.n_carry_over", getValue(pY, "waterquality.n_carry_over"));
                setValue(pYInsert, "waterquality.phosphorus_applied", getValue(pY, "waterquality.phosphorus_applied"));
                setValue(pYInsert, "waterquality.p_soil_test_level", getValue(pY, "waterquality.p_soil_test_level"));
                setValue(pYInsert, "waterquality.p_multi_year_app", getValue(pY, "waterquality.p_multi_year_app"));
                setValue(
                    pYInsert,
                    "waterquality.management_techniques",
                    getValue(pY, "waterquality.management_techniques")
                );
                setValue(pYInsert, "waterquality.pest_management_id", getValue(pY, "waterquality.pest_management_id"));
                setValue(pYInsert, "biodiversity.land_cover_change", getValue(pY, "biodiversity.land_cover_change"));
                setValue(
                    pYInsert,
                    "biodiversity.previous_land_cover",
                    getValue(pY, "biodiversity.previous_land_cover")
                );
                setValue(
                    pYInsert,
                    "biodiversity.field_area_converted",
                    getValue(pY, "biodiversity.field_area_converted")
                );
                setValue(pYInsert, "biodiversity.nutrient_4_rs", getValue(pY, "biodiversity.nutrient_4_rs"));
                setValue(
                    pYInsert,
                    "biodiversity.wildlife_habitat_ids",
                    getValue(pY, "biodiversity.wildlife_habitat_ids")
                );
                setValue(pYInsert, "waterquality.conservation_codes", getValue(pY, "waterquality.conservation_codes"));
            }

            const nY = {
                field: fieldId,
                year: values.year,
                crop: values.primary,
                activeStep: 0
            };

            if (nY.crop === BEANS && pY.crop === BEANS && values.defaults.includes("field")) {
                nY.bean_variety = pY.bean_variety;
            }

            // Later properties overwrite earlier properties with the same name.
            const cropPayload = { ...pYInsert, ...nY };
            cropYearId = ormCropYearCreate(cropPayload);

            if (values.defaults && values.defaults.includes("field")) {
                var irrigation_present = false;
                // Copy all the step 3 activities
                pY._activities.forEach(function (activity) {
                    //activity = Object.assign({}, activity);
                    activity = JSON.parse(JSON.stringify(activity));
                    delete activity.id;
                    delete activity.cropyear_id;
                    delete activity.extrainfo;
                    activity.cropyear = cropYearId;

                    if (activity.type === "irrigation") {
                        irrigation_present = true;
                    }

                    // Clear out planting.energyuse.seeding_rate if different crop
                    if (activity.type === "planting" && pY.crop !== values.primary) {
                        setValue(activity, "planting.energyuse.seeding_rate", null);
                        if (activity.bean_variety_id) {
                            activity.bean_variety_id = null;
                        }
                    }

                    // Clear out drying inputs if not applicable
                    if (activity.type === "harvest" && NOT_DRIED_OR_CUSTOM_DRIED.includes(values.primary)) {
                        DRYING_INPUTS.forEach(name => setValue(activity, name, null));
                    }

                    if (activity._fertilizers) {
                        var _ferts = activity._fertilizers;
                        delete activity._fertilizers;

                        var fieldActivityId = ormFieldActivityCreate(activity);

                        _ferts.forEach(function (f) {
                            f = Object.assign({}, f);
                            delete f.id;
                            f.activity = fieldActivityId;
                            f.activity_id = fieldActivityId;
                            ormFertilizerCreate(f);
                        });
                    } else if (activity._wateruseevent) {
                        var _water = activity._wateruseevent;
                        delete activity._wateruseevent;

                        var id = ormFieldActivityCreate(activity);

                        _water.forEach(function (f) {
                            f = Object.assign({}, f);
                            delete f.id;
                            f.activity = id;
                            f.activity_id = id;
                            setTimeout(function () {
                                ormActivityIrrigationWaterUseEventCreateRemoteFirst(f);
                            }, 5000);
                        });
                    } else {
                        ormFieldActivityCreate(activity);
                    }
                });

                // If the user is creating a rice crop year and the crop year they are copying from doesn't contain an irrigation activity then create one
                if (values.primary === RICE && !irrigation_present) {
                    this.props.ormFieldActivityCreate({
                        cropyear: cropYearId,
                        type: "irrigation",
                        order: 3
                    });
                }
            } else {
                // Otherwise add the defaults
                ormFieldActivityCreate({
                    cropyear: cropYearId,
                    type: "planting",
                    order: 1
                });
                ormFieldActivityCreate({
                    cropyear: cropYearId,
                    type: "harvest",
                    order: 2
                });
                ormFieldActivityCreate({
                    cropyear: cropYearId,
                    type: "application",
                    order: 4,
                    application: {
                        energyuse: {
                            herbicide_count: "0",
                            insecticide_count: "0",
                            fungicide_count: "0",
                            growthregulator_count: "0",
                            fumigant_count: "0",
                            harvestaid_count: "0"
                        }
                    }
                });
            }
        } else {
            // Create a new crop year (but set the previous primary crop and lime year from last years)
            const pY = cropYears.filter(cY => cY.year === (values.year - 1).toString())[0];

            const cropPayload = {
                field: fieldId,
                year: values.year,
                crop: values.primary,
                activeStep: 0,
                greenhouse: {
                    previous_crop_id: getValue(pY, "crop"),
                    yield_previous: getValue(pY, "landuse.yield")
                },
                energyuse: {
                    lime_applied: getValue(pY, "energyuse.lime_applied"),
                    lime_year_applied: getValue(pY, "energyuse.lime_year_applied"),
                    lime_years: getValue(pY, "energyuse.lime_years"),
                    lime_amount: getValue(pY, "energyuse.lime_amount")
                }
            };
            if (values.primary === RICE) {
                cropPayload["is_irrigated"] = true;
            }

            cropYearId = ormCropYearCreate(cropPayload);

            // Add planting, harvest, and application activities right away
            ormFieldActivityCreate({
                cropyear: cropYearId,
                type: "planting",
                order: 1
            });
            ormFieldActivityCreate({
                cropyear: cropYearId,
                type: "harvest",
                order: 2
            });
            ormFieldActivityCreate({
                cropyear: cropYearId,
                type: "application",
                order: 4,
                application: {
                    energyuse: {
                        herbicide_count: "0",
                        insecticide_count: "0",
                        fungicide_count: "0",
                        growthregulator_count: "0",
                        fumigant_count: "0",
                        harvestaid_count: "0"
                    }
                }
            });

            if (values.primary === RICE) {
                this.props.ormFieldActivityCreate({
                    cropyear: cropYearId,
                    type: "irrigation",
                    order: 3
                });
            }
        }
        returnDialog();

        this.props.history.push("/cropyear/" + cropYearId);
    }

    handlePrevYear = value => {
        const { cropYears } = this.props;
        const pY = cropYears.filter(cY => cY.id === value)[0];

        this.loadCropYear(pY);
        this.setState({ previousYear: value });
    };

    handlePrevFarm = value => {
        this.setState({ previousFarm: value, previousField: "empty", previousYear: "empty" });
    };

    handlePrevField = value => {
        this.setState({ previousField: value, previousYear: "empty" });
    };

    showCopyFields = value => {
        const { farm_id, fieldId } = this.props;
        this.formApi.setValue("defaultyear", "empty");
        this.setState({ showCopyFields: value, previousYear: "empty", previousFarm: farm_id, previousField: fieldId });
    };

    errorValidator = values => {
        const { fieldSize } = this.props;
        const validateYear = year => {
            var duplicated = false;
            this.props.cropYearsField.forEach(function (c) {
                if (c.year === year) duplicated = true;
            });
            if (!year) {
                return "Required";
            }
            if (year.length !== 4) {
                return "Invalid year";
            }
            const number = parseFloat(year);
            if (number < 1900 || number > 2100) {
                return "Invalid year";
            }
            if (number % 1 !== 0) {
                return "Invalid year";
            }
            if (duplicated) {
                return "Duplicate year";
            }
            return null;
        };
        const validateNumber = (num, sib) => {
            if (!num) {
                return "Required";
            }
            const number = parseFloat(num);
            if (number < 0 || number > 80000) {
                return "Must be between 0-80000";
            }
            const sibling = parseFloat(sib);
            if (number + sibling < parseFloat(fieldSize)) {
                return "The total number of non-irrigated and irrigated acres cannot be less than the plantable acres for this field";
            }
            return null;
        };
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            year: validateYear(values.year),
            primary: isRequired(values.primary)
        };

        const { showFarmCropYear } = this.state;

        if (showFarmCropYear) {
            valObj["irrigated"] = validateNumber(values.irrigated, values.nonirrigated);
            valObj["nonirrigated"] = validateNumber(values.nonirrigated, values.irrigated);
        }

        return valObj;
    };

    checkFarmCropYear = value => {
        const { farmCropYear, cropLookup } = this.props;

        const addComponent = this;
        setTimeout(function () {
            // The formapi values are not present right away
            const year = addComponent.formApi.getValue("year");
            const primary = addComponent.formApi.getValue("primary");
            if (year && primary) {
                // Check if there already is an existing managed acre record for this year and crop (already filtered by farm)
                // FIXME: New records have a null crop and instead have a crop_id?
                const mALength = farmCropYear.filter(
                    mA => mA.year === year && ((mA.crop && mA.crop.id === primary) || mA.crop_id === primary)
                );
                if (mALength.length > 0) {
                    addComponent.formApi.setValue("nonirrigated", mALength[0].nonirrigated);
                    addComponent.formApi.setValue("irrigated", mALength[0].irrigated);
                    addComponent.setState({
                        showFarmCropYear: true,
                        year: year,
                        crop: cropLookup[primary].name,
                        farmCropYearUpdate: mALength[0].id
                    });
                } else {
                    addComponent.formApi.setValue("nonirrigated", "");
                    addComponent.formApi.setValue("irrigated", "");
                    addComponent.setState({
                        showFarmCropYear: true,
                        year: year,
                        crop: cropLookup[primary].name,
                        farmCropYearUpdate: null
                    });
                }
            } else {
                addComponent.setState({ showFarmCropYear: false });
            }
        }, 100);
    };

    getLast25Years() {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let i = 0; i < 25; i++) {
            years.push(currentYear - i);
        }

        return years;
    }

    render() {
        const { classes, open, allCrops, allDryBeansVarieties, returnDialog, farm_name, cropLookup, isMdUp } = this.props;
        let { allFarms, allFields, cropYears } = this.props;

        const {
            previousYear,
            previousFarm,
            previousField,
            showFarmCropYear,
            crop,
            year,
            farmCropYearUpdate,
            showCopyFields
        } = this.state;

        const last25Yrs = this.getLast25Years();

        function makeOptionsCropYear(table) {
            return table.map(row => ({
                label: row.year + " " + cropLookup[row.crop].name,
                value: row.id
            }));
        }
        const defaultOptions = [
            { label: "Rotation", value: "rotation" },
            { label: "Management", value: "management" },
            { label: "Operations", value: "field" }
        ];

        if (previousFarm !== "empty") {
            allFields = allFields.filter(f => f._fields.farm === previousFarm);
        }

        if (previousField !== "empty") {
            cropYears = cropYears.filter(f => f.field === previousField);
        }


        return (

            <Grid sm={12} style={{ borderRadius: "6px", marginTop: "10px", border: "1px solid #9b9b9b" }}>
                <Form
                    getApi={el => (this.formApi = el)}
                    dontValidateOnMount="true"
                    validateOnSubmit="true"
                    validateError={this.errorValidator}
                    defaultValues={{
                        year: new Date().getFullYear().toString(),
                        defaults: ["rotation", "management", "field"],
                        fa: previousFarm,
                        fi: previousField,
                        defaultyear: previousYear
                    }}
                    onSubmit={values => this.addCropYear(values)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={2} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Grid container item md={3} sm={5} xs={12} spacing={2} style={{ marginTop: "5px", marginBottom: "5px" }} >
                                    <Grid item>
                                        <Typography variant="title" style={{ fontWeight: "600", color: "#5f6368", marginRight: "5px", marginLeft: "15px", marginTop: "5px" }}>YEAR:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            options={last25Yrs}
                                            style={{ marginLeft: "5px" }}
                                            getOptionLabel={(option) => option}
                                            sx={{
                                                minWidth: "120px",
                                                borderRadius: 0,
                                                "& .MuiOutlinedInput-root": {
                                                    borderRadius: 0,
                                                    "& fieldset": {
                                                        borderRadius: 0,
                                                    },
                                                    "& .MuiSelect-select": {
                                                        textAlign: "center",
                                                        border: "1px solid #f15d22",
                                                        borderRadius: 0,
                                                    },
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <div ref={params.InputProps.ref} style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>
                                                    <input
                                                        type="text"
                                                        {...params.inputProps}
                                                        placeholder="Select Year"
                                                        style={{
                                                            width: "100px",
                                                            padding: "10px",
                                                            borderRadius: "0px",
                                                            border: "1px solid #c4c4c4",
                                                            boxSizing: "border-box",
                                                            color: "#c4c4c4",
                                                            outline: params.inputProps['aria-expanded'] ? '2px solid #f15d22' : 'none'
                                                        }}
                                                    />
                                                    <KeyboardArrowDownIcon style={{ position: 'absolute', right: '10px', pointerEvents: 'none' }} />
                                                </div>

                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item md={6} sm={7} xs={12} spacing={2} justifyContent="flex-start" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                    <Grid item>
                                        <Typography variant="title" style={{ fontWeight: "600", color: "#5f6368", marginLeft: "15px", marginTop: "5px" }}>CROP</Typography>
                                    </Grid>
                                    <Grid item>

                                        <Autocomplete
                                            options={cropOptions}
                                            style={{ marginLeft: "10px" }}
                                            groupBy={(option) => option.category}
                                            getOptionLabel={(option) => option.crop}
                                            sx={{
                                                minWidth: "230px",
                                                borderRadius: 0,
                                                "& .MuiOutlinedInput-root": {
                                                    borderRadius: 0,
                                                    "& fieldset": {
                                                        borderRadius: 0,
                                                    },
                                                    "& .MuiSelect-select": {
                                                        textAlign: "center",
                                                        border: "1px solid #f15d22",
                                                        borderRadius: 0,
                                                    },
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <div ref={params.InputProps.ref} style={{ display: 'flex', alignItems: 'center', width: '200px', position: 'relative' }}>
                                                    <input
                                                        type="text"
                                                        {...params.inputProps}
                                                        placeholder="Select Crop"
                                                        style={{
                                                            width: "200px",
                                                            padding: "10px",
                                                            borderRadius: "0px",
                                                            border: "1px solid #c4c4c4",
                                                            boxSizing: "border-box",
                                                            color: "#c4c4c4",
                                                            outline: params.inputProps['aria-expanded'] ? '2px solid #f15d22' : 'none'
                                                        }}
                                                    />
                                                    <KeyboardArrowDownIcon style={{ position: 'absolute', right: '10px', pointerEvents: 'none' }} />
                                                </div>

                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item md={3} sm={12} xs={12} spacing={2} justifyContent={"flex-end"}>
                                    <Grid item >
                                        <Button
                                            style={{ backgroundColor: "#5388d8", color: "#ffffff", marginRight: "15px", marginTop: "5px" }}
                                            type="submit"
                                            variant="contained"
                                            startIcon={<AddCircleOutlineIcon />}

                                        >
                                            ADD NEW CROP
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </form>
                    )}
                </Form>
            </Grid>

        );
    }
}

AddCropYearInline = connect(
    (state, ownProps) => ({
        allCrops: allCrops(state),
        allDryBeansVarieties: allDryBeansVarieties(state),
        allFarms: allFarms(state),
        allFields: allFields(state),
        farmCropYear: getFarmCropYear(state, ownProps),
        cropLookup: state.orm.Crops.itemsById,
        cropYears: getCropYears(state, ownProps)
    }),
    {
        ...FarmCropYear.actions,
        ...Fertilizer.actions,
        ...CropYear.actions,
        ...FieldActivity.actions,
        ...ActivityIrrigationWaterUseEvent.actions
    }
)(AddCropYearInline);

export default withRouter(withStyles(styles)(AddCropYearInline));
