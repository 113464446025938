import React, { Component } from 'react';
import { ToggleButtonGroup, ToggleButton, Container, Box } from '@mui/material';

class ToggleBadgeButtonGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonStatus: 'false',
        };
    }

    handleStatus = (event, newStatus) => {
        if (newStatus !== null) {
            this.setState({ buttonStatus: newStatus });
        }
    };

    render() {
        const { buttonStatus } = this.state;
        const { falseText, trueText } = this.props;

        return (
            <Container>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ToggleButtonGroup
                        value={buttonStatus}
                        exclusive
                        onChange={this.handleStatus}
                        aria-label="text status"
                    >
                        <ToggleButton value="false" aria-label='false' style={{
                            fontSize: "12px",
                            color: "#616452",
                            backgroundColor: "#f4be37",
                            padding: "4px"
                        }} >
                            {falseText}
                        </ToggleButton>
                        <ToggleButton value="false" aria-label='false' style={{
                            fontSize: "12px",
                            color: "#616452",
                            backgroundColor: "#eaeaeb",
                            padding: "4px"
                        }}>
                            {trueText}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Container>
        );
    }
}

export default ToggleBadgeButtonGroup;