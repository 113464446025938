import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

import Button from "../common/ButtonWrapper";
import Divider from "@mui/material/Divider";
import Grid from "../common/GridWrapper";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import EyeIcon from "../common/icons/EyeIcon";
import MapIcon from "@mui/icons-material/Map";
import MenuIcon from "@mui/icons-material/Menu";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import { CROP_YEAR_COMPLETED } from "../field/fieldactivities/models";
import DashboardMap from "./DashboardMap";
import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import PageHeader from "../common/PageHeader";
import TablePaginationActionsWrapped from "../common/Paginator";
import Select from "../common/Select";
import CustomTableCell from "../common/TableCell";
import ResearchDialog from "../research/ResearchDialog";
import * as navActions from "../common/actions";
import { createSelector } from "../common/orm";
import { Field, Farm } from "../field/models";

const allFarms = Farm.selectAll();
const allCrops = createSelector(schema => {
    return schema.Crops.all().orderBy("name").toModelArray();
});
const allFields = Field.selectAll(field => {
    const lastCropYear = field.cropyears.all().orderBy("year", "desc").first();
    return {
        farm_id: field.farm.id,
        farm_name: field.farm.name,
        last_crop: lastCropYear && lastCropYear.crop.name,
        last_crop_id: lastCropYear && lastCropYear.crop.id,
        last_crop_year: lastCropYear && lastCropYear.year,
        ...field.ref
    };
});
const allCropYears = createSelector(
    // Custom Selector for orderBy
    schema => {
        if (schema.Field.count() > 0 && schema.Farm.count() > 0) {
            const schemaProject = schema.Project.count();
            return schema.CropYear.all()
                .orderBy("year", "desc")
                .toModelArray()
                .map(cropYear => ({
                    // FIXME: These "if name else id" statements can probably be removed since the name is now forced to be defined
                    farm_name: cropYear.field.farm.name ? cropYear.field.farm.name : cropYear.field.farm.id,
                    farm_id: cropYear.field.farm.id,
                    field_name: cropYear.field.name ? cropYear.field.name : cropYear.field.id,
                    field_id: cropYear.field.id,
                    field_acres: cropYear.field.size && cropYear.field.size.split(" ")[0],
                    crop_name: cropYear.crop.name,
                    project_code:
                        schemaProject > 0 && cropYear.project_instance ? cropYear.project_instance.name : "N/A",
                    ...cropYear.ref,
                    fieldObject: cropYear.field._fields
                }));
        } else {
            return [];
        }
    }
);

const styles = theme => ({
    grey: {
        backgroundColor: "#eeeeee",
        border: "4px solid #ffffff",
        whiteSpace: "nowrap"
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px"
    },
    centerAlign: {
        textAlign: "center"
    },
    standardMargin: {
        marginLeft: 8,
        marginRight: 8,
        marginTop: 8
    },
    divideButton: {
        marginBottom: 8,
        width: "50%",
        marginTop: 16,
        minWidth: 44
    },
    filterButton: {
        marginBottom: 8,
        width: "100%",
        border: "1px solid #666666"
    },
    buttonSelected: {
        border: "3px solid #00adee",
        minWidth: "44px"
    },
    iconSelected: {
        color: "#00adee"
    },
    mapContainer: {
        height: 600,
        paddingRight: "4px !important",
        paddingLeft: "4px !important"
    },
    smallerIcon: {
        width: ".9em"
    },
    paddingRight: {
        paddingRight: "24px !important"
    },
    paddingRightField: {
        [theme.breakpoints.down("lg")]: {
            paddingRight: "24px !important"
        }
    },
    marker: {
        height: 18,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: -3
    },
    analyticsTooltip: {
        // Tooltips don't work on disabled buttons without div, style div as button
        display: "inline-block",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    },

    // Facts
    hidden: {
        opacity: 0,
        overflow: "hidden",
        padding: "0 !important",
        height: "0 !important"
    },
    panelText: {
        color: "#fff",
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: "#00adee",
        marginLeft: "auto",
        marginRight: "auto",
        width: "155px",
        borderBottomLeftRadius: theme.spacing(3),
        borderBottomRightRadius: theme.spacing(3),
        padding: "0 16px 0 8px"
    },
    panelContainer: {
        padding: "20px",
        height: "130px",
        width: "100%",
        transition: theme.transitions.create(["height", "opacity"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    button: {
        height: "20px",
        width: "20px",
        color: "#fff"
    },
    marginTop: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: "#00adee"
    },
    floatRight: {
        float: "right",
        marginRight: theme.spacing(1)
    },
    iconText: {
        height: 18,
        width: 24,
        marginBottom: -3
    },
    underlineBottom: {
        borderBottom: "1px dotted",
        marginBottom: theme.spacing(2),
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666"
    },
    linkColor: {
        color: "#808080"
    }
});

const columnData = [
    {
        id: "farm_name",
        numeric: false,
        label: "Farm",
        allowSort: true,
        width: "15%"
    },
    {
        id: "field_name",
        numeric: false,
        label: "Field",
        allowSort: true,
        width: "15%"
    },
    {
        id: "field_acres",
        numeric: true,
        label: "Acres",
        allowSort: true,
        width: "10%"
    },
    { id: "year", numeric: true, label: "Year", allowSort: true, width: "10%" },
    {
        id: "crop_name",
        numeric: false,
        label: "Crop",
        allowSort: true,
        width: "10%"
    },
    {
        id: "project_code",
        numeric: false,
        label: "Project",
        allowSort: true,
        width: "15%"
    },
    {
        id: "finalized",
        numeric: false,
        label: "Finalized*",
        allowSort: true,
        width: "12%"
    },
    {
        id: "actions",
        numeric: false,
        label: "View",
        allowSort: false,
        width: "13%"
    }
];

class CropLibrary extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "year",
            page: 0,
            rowsPerPage: 10
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        const isNumeric = columnData.filter(function (item) {
            if (item.id === property) return item;
            else return null;
        });
        if (isNumeric[0].numeric) {
            order === "desc"
                ? this.props.cropYears.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                : this.props.cropYears.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        } else {
            order === "desc"
                ? this.props.cropYears.sort((a, b) => (b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1))
                : this.props.cropYears.sort((a, b) => (a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1));
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { cropYears, classes, navSelectPage } = this.props;

        const { order, orderBy, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, cropYears.length - page * rowsPerPage);

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData}
                />
                <TableBody>
                    {cropYears.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(cropYear => (
                        <TableRow key={cropYear.id} hover>
                            <CustomTableCell>
                                <Link
                                    className={classes.linkColor}
                                    onClick={() => navSelectPage("farm_" + cropYear.farm_id)}
                                    to={"/farm/" + cropYear.farm_id + "/edit"}>
                                    {cropYear.farm_name}
                                </Link>
                            </CustomTableCell>
                            <CustomTableCell>
                                <Link
                                    className={classes.linkColor}
                                    onClick={() => navSelectPage("field_" + cropYear.field_id)}
                                    to={"/fieldold/" + cropYear.field_id}>
                                    {cropYear.field_name}
                                </Link>
                            </CustomTableCell>
                            <CustomTableCell>{cropYear.field_acres}</CustomTableCell>
                            <CustomTableCell>{cropYear.year}</CustomTableCell>
                            <CustomTableCell>{cropYear.crop_name}</CustomTableCell>
                            <CustomTableCell>{cropYear.project_code}</CustomTableCell>
                            <CustomTableCell>{cropYear.is_final ? "Yes" : "No"}</CustomTableCell>
                            <CustomTableCell>
                                <Tooltip title="View Crop Year Details">
                                    <Button
                                        onClick={() => navSelectPage("field_" + cropYear.field_id)}
                                        component={Link}
                                        to={"/cropyear/" + cropYear.id}
                                        className={classes.buttonWidth}>
                                        <RemoveRedEyeIcon color="primary" />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        CROP_YEAR_COMPLETED(cropYear)
                                            ? cropYear.metrics_version
                                                ? "Analytics - Computed"
                                                : "Analytics - Not Yet Computed"
                                            : " Analytics (Complete Data Entry to View)"
                                    }>
                                    <div className={classes.analyticsTooltip}>
                                        <Button
                                            disabled={!CROP_YEAR_COMPLETED(cropYear)}
                                            component={Link}
                                            to={"/cropyear/" + cropYear.id + "/analysis"}
                                            className={classes.buttonWidth}>
                                            {CROP_YEAR_COMPLETED(cropYear) && cropYear.metrics_version && (
                                                <EqualizerIcon style={{ color: "green" }} />
                                            )}
                                            {CROP_YEAR_COMPLETED(cropYear) && !cropYear.metrics_version && (
                                                <EqualizerIcon color="primary" />
                                            )}
                                            {!CROP_YEAR_COMPLETED(cropYear) && <EqualizerIcon />}
                                        </Button>
                                    </div>
                                </Tooltip>
                            </CustomTableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && cropYears.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={8} />
                        </TableRow>
                    )}
                    {cropYears.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={8} className={classes.centerAlign}>
                                No Crop Years Entered
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {cropYears.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={8}
                                count={cropYears.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

class DashboardFacts extends Component {
    state = {
        expanded: false
    };

    render() {
        const { classes, cropYears, fields, numFarms } = this.props;
        const { expanded } = this.state;

        // Calc totals
        var totalFieldAcres = 0,
            minYear,
            maxYear,
            crops = [],
            cropString = "",
            projects = [];
        cropYears.forEach(function (cY) {
            if (cY.year <= minYear || minYear === undefined) minYear = cY.year;
            if (cY.year >= maxYear || maxYear === undefined) maxYear = cY.year;
            if (!crops.includes(cY.crop_name)) crops.push(cY.crop_name);
            if (!projects.includes(cY.project_code) && cY.project_code !== "N/A") projects.push(cY.project_code);
        });
        crops.forEach(function (c) {
            cropString += c + ", ";
        });
        if (cropString !== "") cropString = cropString.slice(0, -2);
        fields.forEach(function (f) {
            if (f.size) totalFieldAcres += parseFloat(f.size);
        });
        const totalFields = fields.length;

        return (
            <div>
                <Grid
                    container
                    spacing={24}
                    className={classNames(classes.panelContainer, !expanded && classes.hidden)}>
                    <Grid item xs={4} xl={3}>
                        <Typography className={classes.underlineBottom}>
                            <b>Total Fields</b>: {totalFields}
                        </Typography>
                        <Typography className={classes.underlineBottom}>
                            <b>Total Field Acres</b>: {totalFieldAcres.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} xl={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={4}>
                                <Typography className={classes.underlineBottom}>
                                    <b>Years</b>: {minYear ? minYear + " - " + maxYear : ""}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.underlineBottom}>
                                    <b>Total Projects</b>: {projects.length}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.underlineBottom}>
                                    <b>Total Farms</b>: {numFarms}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography className={classes.underlineBottom}>
                            <b>Crops</b>: {cropString}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} xl={3} />
                </Grid>
                {expanded && (
                    <div>
                        <Divider className={classes.divider} />
                        <Typography onClick={() => this.setState({ expanded: false })} className={classes.panelText}>
                            <ExpandLess className={classes.button} />
                            <span className={classes.floatRight}>Hide Quick Facts</span>
                        </Typography>
                    </div>
                )}
                {!expanded && (
                    <div className={classes.marginTop}>
                        <Divider className={classes.divider} />
                        <Typography onClick={() => this.setState({ expanded: true })} className={classes.panelText}>
                            <EyeIcon className={classes.button} />
                            <span className={classes.floatRight}>View Quick Facts</span>
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

class Dashboard extends Component {
    state = {
        farmFilter: null,
        fieldFilter: null,
        cropFilter: null,
        yearFilter: null
    };
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        var { classes, cropYears, fields, navSelectPage, allCrops, allFarms, dashToggleMapTable, navState } =
            this.props;
        const { farmFilter, fieldFilter, cropFilter, yearFilter } = this.state;

        function makeOptions(table) {
            table = table.map(row => ({
                label: row.name ? row.name : row.id,
                value: row.id,
                key: row.id
            }));
            table.unshift({ label: <b>Clear Filter</b>, value: null, key: null });
            return table;
        }

        function makeYearOptions(years) {
            years = years.map(row => ({
                label: row,
                value: row,
                key: row
            }));
            years.unshift({ label: <b>Clear Filter</b>, value: null, key: null });
            return years;
        }
        const cropYearsFacts = cropYears;
        const fieldsFacts = fields;

        var years = [];
        cropYears.forEach(function (cY) {
            if (!years.includes(cY.year)) years.push(cY.year);
        });
        years.sort();
        // Filters
        var farmSort = allFarms.sort((a, b) =>
            (a.name ? a.name : a.id).toLowerCase() < (b.name ? b.name : b.id).toLowerCase() ? -1 : 1
        );
        var fieldsSort = fields.sort((a, b) =>
            (a.name ? a.name : a.id).toLowerCase() < (b.name ? b.name : b.id).toLowerCase() ? -1 : 1
        );

        if (farmFilter) {
            cropYears = cropYears.filter(cY => cY.farm_id === farmFilter);
            fields = fields.filter(f => f.farm_id === farmFilter);
        }
        if (fieldFilter) {
            cropYears = cropYears.filter(cY => cY.field_id === fieldFilter);
            fields = fields.filter(f => f.id === fieldFilter);
        }
        if (cropFilter) {
            cropYears = cropYears.filter(cY => cY.crop === cropFilter);
            fields = fields.filter(f => f.last_crop_id === cropFilter);
        }
        if (yearFilter) {
            cropYears = cropYears.filter(cY => cY.year === yearFilter);
            fields = fields.filter(f => f.last_crop_year === yearFilter);
        }

        var key;
        if (window.location.host.includes("fieldtomarket.org")) {
            key = "AIzaSyBeppehipaycm7a0WRPhbN9MTq4rpGWH4k";
        } else {
            key = "AIzaSyCqjdm8s3eVGfyYxAW_fHFy7_m0ERTSVQQ";
        }

        if (!navState.selectedButton) dashToggleMapTable("table");

        return (
            <AppContainer
                authenticated
                header={
                    <>
                        <PageHeader title="Dashboard" color="dashboard" />
                        <DashboardFacts
                            classes={classes}
                            cropYears={cropYearsFacts}
                            fields={fieldsFacts}
                            numFarms={allFarms.length}
                        />
                        <ResearchDialog />
                    </>
                }>
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Typography>
                            This page provides a dashboard view of your Farms and Fields. You can view your fields in
                            map view (
                            <MapIcon className={classes.iconText} />) or in table view (
                            <MenuIcon className={classes.iconText} />
                            ). Click on a map marker (
                            <img className={classes.marker} alt="marker" src="/static/icons/marker-000000.png" />) or
                            the field boundary (depending on zoom level) to see additional field details and link to the
                            field dashboard.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <Grid item xs={9}>
                                <Typography variant="title">Filter by Category</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="title">Select View</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Form dontValidateOnMount="true" validateOnSubmit="true">
                            {formApi => (
                                <form>
                                    <Grid container spacing={16} alignItems="center">
                                        <Grid item xs={9}>
                                            <Grid container spacing={16} className={classes.grey} alignItems="flex-end">
                                                <Grid item xs={6} lg={3}>
                                                    <Select
                                                        eventHandle={value => this.setState({ farmFilter: value })}
                                                        field="dfarm"
                                                        label="Farm"
                                                        className={classes.standardMargin}
                                                        options={makeOptions(farmSort)}
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={6} lg={3} className={classes.paddingRightField}>
                                                    <Select
                                                        eventHandle={value => this.setState({ fieldFilter: value })}
                                                        field="dfield"
                                                        label="Field"
                                                        className={classes.standardMargin}
                                                        options={makeOptions(fieldsSort)}
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={4} lg={2}>
                                                    <Select
                                                        eventHandle={value => this.setState({ cropFilter: value })}
                                                        field="dcrop"
                                                        label="Crop"
                                                        className={classes.standardMargin}
                                                        options={makeOptions(allCrops)}
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={4} lg={2}>
                                                    <Select
                                                        eventHandle={value => this.setState({ yearFilter: value })}
                                                        field="dyear"
                                                        label="Year"
                                                        className={classes.standardMargin}
                                                        options={makeYearOptions(years)}
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={4} lg={2} className={classes.paddingRight}>
                                                    <Button
                                                        className={classNames(
                                                            classes.standardMargin,
                                                            classes.filterButton
                                                        )}
                                                        onClick={() => {
                                                            this.setState({
                                                                farmFilter: "",
                                                                fieldFilter: "",
                                                                cropFilter: "",
                                                                yearFilter: ""
                                                            });
                                                            formApi.resetAll();
                                                        }}>
                                                        <Typography>Clear Filters</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3} className={classes.grey}>
                                            <Button
                                                title="Map"
                                                onClick={() => dashToggleMapTable("map")}
                                                className={classNames(
                                                    classes.divideButton,
                                                    navState.selectedButton === "map" && classes.buttonSelected
                                                )}>
                                                <MapIcon
                                                    className={classNames(
                                                        classes.smallerIcon,
                                                        navState.selectedButton === "map" && classes.iconSelected
                                                    )}
                                                />
                                            </Button>
                                            <Button
                                                title="Table"
                                                onClick={() => dashToggleMapTable("table")}
                                                className={classNames(
                                                    classes.divideButton,
                                                    navState.selectedButton === "table" && classes.buttonSelected
                                                )}>
                                                <MenuIcon
                                                    className={classNames(
                                                        classes.smallerIcon,
                                                        navState.selectedButton === "table" && classes.iconSelected
                                                    )}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                    {navState.selectedButton === "map" && (
                        <Grid item xs={12} className={classes.mapContainer}>
                            <DashboardMap
                                googleMapURL={
                                    "https://maps.googleapis.com/maps/api/js?key=" +
                                    key +
                                    "&v=3.exp&libraries=geometry,drawing,places"
                                }
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `100%` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                fields={fields}
                                allCrops={allCrops}
                            />
                        </Grid>
                    )}
                    {navState.selectedButton === "table" && (
                        <Grid item xs={12} className={classes.mapContainer}>
                            <CropLibrary navSelectPage={navSelectPage} cropYears={cropYears} classes={classes} />
                            <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                * Fieldprint Data in the Platform has two states - provisional and finalized. Until a
                                Field is finalized (options available on the Analysis page for a given field), data is
                                provisional and changes can be made. Once finalized, Fieldprint Data can be reported.
                                You should finalize a Field once all data has been entered, finalized, and validated.
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </AppContainer>
        );
    }
}

Dashboard = connect(
    state => ({
        cropYears: allCropYears(state),
        allCrops: allCrops(state),
        allFarms: allFarms(state),
        fields: allFields(state),
        navState: state.nav
    }),
    navActions
)(Dashboard);

export default withStyles(styles)(Dashboard);
