import React from "react";
import { connect } from "react-redux";
import Grid from "../common/GridWrapper";
import { ListItem } from "@mui/material";
import classNames from "classnames";
import Typography from "../common/TypographyWrapper";
import CropTypeIcon from "../common/icons/CropTypeIcon";
import { apiFetch, dbFetch } from "../../api/fetch";
import saveAs from "file-saver";
import { toggleAccordian } from '../common/actions';
import ActionMenu from "../common/ActionMenu";
import withRouter from "../common/withRouter";
import { CropYear } from "../field/fieldactivities/models";
import { Field } from "../field/models";
import { getValue } from "../../api/utils";
import { Metric } from "../../api/models";
import { Project, ProjectUser } from "../project/models";

const allProjectUsers = ProjectUser.selectAll(PU => ({
    project_attributes: PU.project,
    ...PU.ref
}));

class CropYearComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            downloading: false,
            projectPopupOpen: false,
            cropYearPopupOpen: false,
            checked: false,
            projectEnroll: null,
            cropYearView: 0,
            position: {
                top: 0,
                left: 0,
            },
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null
        };
    }

    componentDidMount() {

    }

    handleTooltipMove = (event) => {
        this.setState({
            position: {
                top: event.clientY,
                left: event.clientX,
            }
        });
    };

    async download() {
        this.setState({ downloading: true });

        const { authState, cropHistory } = this.props;
        let response;
        if (authState.user && authState.user.guest) {
            response = await apiFetch("/api/v4/calc/CalculatorReport", {
                method: "POST",
                body: JSON.stringify(cropHistory),
                headers: { Accept: "application/pdf" }
            });
        } else {
            response = await dbFetch(`/reports/${cropHistory.id}/pdf`);
        }

        saveAs(await response.blob(), cropHistory.id + ".pdf");

        this.setState({ downloading: false });
    }

    handleRecordStatusChange = () => {

        this.props.ormCropYearUpdate({
            id: this.props.cropHistory.id,
            is_final: !this.props.cropHistory.is_final
        });

    };

    handleCloneCropYear = () => {
        var { cropHistory } = this.props;

        this.props.ormCropYearCreate({
            ...cropHistory
        });
    };

    deleteCropYear = (crop, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: crop,
            deleteDialogText: "Are you sure you wish to permanently delete " + name + "?"
        });
    };

    handleMetricClick = () => {
        this.props.toggleAccordian("METRIC-" + this.props.cropHistory.id);
    };

    handleOperationClick = () => {
        this.props.toggleAccordian("OPERATION-" + this.props.cropHistory.id);
    };

    handlePopupClickOpen = (event) => {
        event.preventDefault();
        this.setState({ projectPopupOpen: true });
    }

    handleCropYearPopupClickOpen = (projid) => {

        this.setState({ cropYearPopupOpen: true, projectEnroll: projid, cropYearView: 0 });
    }

    handleRemoveCropYearPopupClickOpen = (projid) => {

        this.setState({ cropYearPopupOpen: true, projectEnroll: projid, cropYearView: 3 });
    }

    handlePopupClickClose = () => {
        this.setState({ projectPopupOpen: false });
    }

    handleCropYearPopupClickClose = () => {
        this.setState({ cropYearPopupOpen: false, cropYearView: 0 });
    }

    handleEditCropYearClick = () => {
        var { cropHistory, history } = this.props;

        history.push("/cropyear/" + cropHistory.id);
    }

    handleViewMetrics = () => {
        var { cropHistory, history } = this.props;

        history.push("/cropyear/" + cropHistory.id + "/analysis");
    }

    handleDownloadReportClick = () => {
        this.download();
    }

    handleRunMetrics = () => {
        const { cropHistory, runMetric } = this.props;

        const pending = getValue(cropHistory, "metrics.Calculator.pending");

        if (pending === undefined) {
            runMetric("Calculator", cropHistory.id, true);
        }

    }

    render() {

        var { cropHistory, classes } = this.props;

        return (
            <Grid key={cropHistory.id} container maxWidth="lg" style={{ marginTop: "25px", border: "solid 1px #bcbcbc", borderRadius: "6px" }}>
                <Grid item container >
                    <Grid item sm={1} xs={0}>
                        <CropTypeIcon className={classNames(classes.iconSize, "hideWhenSmall")} style={{ margin: "15px" }} />
                    </Grid>
                    <Grid item sm={11} xs={12} >
                        <ListItem>
                            <Grid container xs={12} >
                                <Grid container sm={9}>
                                    <Grid item xs={12}>

                                        <Typography style={{ fontSize: "18px", color: "#212121", textDecoration: "underline" }}>
                                            {cropHistory.year + " " + cropHistory.crop_name}
                                        </Typography>

                                    </Grid>
                                </Grid>
                                <Grid item sm={3} alignItems="flex-end" >
                                    <ActionMenu
                                        title="Actions"
                                        variant="contained"
                                        style={{ backgroundColor: "#6d8d22", color: "white", height: "30px", minWidth: "110px", float: "right" }}
                                        actions={[
                                            // { name: "Edit Crop Year", onClick: this.handleEditCropYearClick },
                                            // { name: "Clone Crop Year", onClick: () => this.handleCloneCropYear() },
                                            // { name: "Delete Crop Year", onClick: () => this.deleteCropYear(cropHistory.id, cropHistory.year + " " + cropHistory.crop_name) }
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>
                    </Grid>
                </Grid >
            </Grid >
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    isMetricExpanded: state.nav.dashboardAccordian != null ? state.nav.dashboardAccordian["METRIC-" + ownProps.cropHistory.id] : false || false,
    isOperationExpanded: state.nav.dashboardAccordian != null ? state.nav.dashboardAccordian["OPERATION-" + ownProps.cropHistory.id] : false || false,
    projects: allProjectUsers(state)
});
const mapDispatchToProps = {
    toggleAccordian,
    ...CropYear.actions,
    ...Metric.actions,
    ...Field.actions,
    ...Project.actions,
    ...ProjectUser.actions
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CropYearComponent));