import React from "react";
import { GeoJSON2SVG } from "geojson2svg";

class FarmBoundaryIcon extends React.Component {

    render() {

        const { geojson } = this.props;

        if (geojson != null) {
            const converter = new GeoJSON2SVG({
                viewportSize: { width: 70, height: 70 },
                attributes: {
                    fill: "#babdad", stroke: "#42472a", strokeWidth: "3", opacity: "0.5"
                },
                r: 2
            });

            const svgPaths = converter.convert(geojson, { output: "svg" });

            const svgContent = svgPaths.join('').replace("strokeWidth", "stroke-width");

            return <svg height="70" width="70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115 115" dangerouslySetInnerHTML={{ __html: svgContent }} />
        } else {
            return (
                <svg height="70" width="70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115 115">
                    <path d="M1.3140664545989134,0 128.12649484702828,1.015182356800617 126.81242839233241,100 0,98.98468116638263 1.3140664545989134,0Z" fill="#babdad" stroke="#42472a" strokeWidth="3" opacity="0.5" />
                </svg>
            )
        }

    }

}

export default FarmBoundaryIcon;