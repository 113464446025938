import React from "react";
import { Dialog, DialogActions, DialogContent, Button, Radio, RadioGroup, Grid, Select, MenuItem } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "../common/TypographyWrapper";
import Close from "@mui/icons-material/CloseOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import { connect } from "react-redux";
import { Project } from "../project/models";
import { CropYear } from "../field/fieldactivities/models";


const cancelButtonStyle = {
    color: '#6d8d22',
    borderColor: '#6d8d22',
    backgroundColor: 'white',
    border: '1px solid',
    height: '35px',
    borderRadius: '3px'
};

const confirmButtonStyle = {
    color: 'white',
    backgroundColor: '#6d8d22',
    height: '35px',
    borderRadius: '3px'
};

class CropYearEnrollDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: 0,
            initView: 0,
            selectedValue: "single",
            selectedProjectId: null,
            selectedProject: null
        };

    }

    applyCropYear = (addCropYear, cropYearId) => {
        const { ormCropYearUpdate, ormProjectLoadDetail } = this.props;
        ormCropYearUpdate({
            id: cropYearId,
            project_instance: addCropYear ? this.state.selectedProjectId : null
        });
        //FIXME: WHERE IS THE UPDATE CALLBACK??
        const projId = this.state.selectedProjectId;
        setTimeout(function () {
            ormProjectLoadDetail(projId);
        }, 500);
    };

    handleEnrollCropYear = () => {
        const selectedProject = this.props.projects.find(item => item.project_attributes.id === this.state.selectedProjectId);
        this.setState({ view: 1, selectedProject: selectedProject !== null ? selectedProject.project_attributes : null });
    };

    handleEnrollAllCropYears = () => {
        const { cropHistory, allCropHistory } = this.props;

        if (this.state.selectedValue === "single") {
            this.applyCropYear(true, cropHistory.id)
        } else if (this.state.selectedValue === "multiple") {
            allCropHistory.map(cy => {
                if (this.state.selectedProject.crops_label.includes(cy.crop_name)) {
                    if (cy.project_instance === null || cy.project_instance === undefined) {
                        this.applyCropYear(true, cy.id);
                    }
                }
            });
        }

        this.setState({ view: 2 });
    };

    handleUnenrollCropYear = () => {
        const { cropHistory } = this.props;

        this.applyCropYear(false, cropHistory.id);

        this.handleClose();
    };

    handleChange = (event) => {
        this.setState({ selectedValue: event.target.value });
    };

    handleClose = () => {
        this.props.handlePopupClickClose();
        this.setState({ selectedValue: "single", view: 0 });
    }

    handleSelectChange = (event) => {
        this.setState({ selectedProjectId: event.target.value });
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            if (this.props.projects.length > 0) {
                // set a default value
                this.setState({ selectedProjectId: this.props.projects[0].project_attributes.id });
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.initView !== state.initView) {
            return { view: props.initView };
        }
        return null;
    }

    render() {

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="form-dialog-title"
                style={{ textAlign: "center", minHeight: "300px" }}
                fullWidth
                maxWidth="xs"
            >
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: "#f15d22", minHeight: 50 }}>
                    <Typography variant="title">

                    </Typography>
                    <Tooltip title="Close">
                        <IconButton size="large" style={{ color: "white" }} onClick={() => this.props.handlePopupClickClose()} >
                            <Close color="white" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <DialogContent>
                    {this.state.view === 0 &&
                        <div>
                            <Typography variant="title" style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                                Select a Fieldprint Project
                            </Typography>
                            <Typography variant="h6" style={{ paddingBottom: "10px", color: "grey" }}>
                                Select a project in which to enroll this crop year.
                            </Typography>
                            <Select
                                labelId="fp-project-select-label"
                                id="fp-project-select"
                                value={this.state.selectedProjectId}
                                onChange={this.handleSelectChange}
                            >
                                {this.props.projects.map(({ project_attributes }) => (
                                    <MenuItem key={project_attributes.id} value={project_attributes.id}>{project_attributes.name}</MenuItem>
                                ))}
                            </Select>

                        </div>
                    }
                    {this.state.view === 1 &&
                        <div>
                            <Typography variant="title" style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                                Select a Fieldprint Project
                            </Typography>
                            <Typography variant="h6" style={{ paddingBottom: "10px", color: "green" }}>
                                {this.state.selectedProject !== null ? this.state.selectedProject.name : ""}
                            </Typography>
                            <Typography style={{ paddingBottom: "10px", color: "grey", fontSize: "16px" }}>
                                Enroll {this.props.cropHistory != null ? this.props.cropHistory.year + " " + this.props.cropHistory.crop_name : ""} on {this.props.cropHistory != null && this.props.cropHistory.fieldObject != null ? this.props.cropHistory.fieldObject.name : ""} in the selected project? If desired, you can enroll all crop years from this field at one time. Note that most projects are limited to specific crops.
                            </Typography>

                            <RadioGroup
                                row
                                aria-label="enrollment type"
                                value={this.state.selectedValue}
                                onChange={this.handleChange}
                            >
                                <Grid container justify="center">
                                    <FormControlLabel
                                        style={{ paddingLeft: "35px" }}
                                        value="single"
                                        control={
                                            <Radio
                                                color="primary"
                                            />
                                        }
                                        label="Enroll Crop Year"
                                    />
                                    <FormControlLabel
                                        value="multiple"
                                        control={
                                            <Radio
                                                color="primary"
                                            />
                                        }
                                        label="Enroll all Crop Years"
                                    />
                                </Grid>
                            </RadioGroup>
                        </div>
                    }
                    {this.state.view === 2 &&
                        <div>
                            <Typography variant="title" style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                                Select a Fieldprint Project
                            </Typography>
                            <Typography variant="h6" style={{ paddingBottom: "10px", color: "green" }}>
                                {this.state.selectedProject !== null ? this.state.selectedProject.name : ""}
                            </Typography>
                            <CheckCircleIcon style={{ color: "#f15d22" }} />
                            <Typography style={{ color: "black", fontSize: "20px" }}>
                                Complete!
                            </Typography>
                        </div>
                    }
                    {this.state.view === 3 &&
                        <div>
                            <Typography variant="title" style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                                Unenroll Crop Year from Fieldprint Project
                            </Typography>
                            <Typography variant="h6" style={{ paddingBottom: "10px", color: "green" }}>
                                {this.state.selectedProject !== null ? this.state.selectedProject.name : ""}
                            </Typography>
                            <WarningAmberIcon style={{ color: "#f15d22" }} />
                        </div>
                    }

                </DialogContent>

                <DialogActions style={{ justifyContent: 'center', paddingTop: '8px', marginBottom: '20px' }}>
                    {(this.state.view === 0 || this.state.view === 1 || this.state.view === 3) && <Button style={cancelButtonStyle} onClick={() => this.handleClose()}>Close</Button>}
                    {this.state.view === 0 && <Button style={confirmButtonStyle} onClick={() => this.handleEnrollCropYear()}>Next</Button>}
                    {this.state.view === 1 && <Button style={confirmButtonStyle} onClick={() => this.handleEnrollAllCropYears()}>Confirm</Button>}
                    {this.state.view === 2 && <Button style={confirmButtonStyle} onClick={() => this.handleClose()}>Close</Button>}
                    {this.state.view === 3 && <Button style={confirmButtonStyle} onClick={() => this.handleUnenrollCropYear()}>Confirm</Button>}
                </DialogActions>
            </Dialog>
        )
    }
}

CropYearEnrollDialog = connect(
    state => ({
        authState: state.auth
    }),
    {
        ...Project.actions,
        ...CropYear.actions
    }
)(CropYearEnrollDialog)

export default CropYearEnrollDialog;