import { useRef, useCallback, useEffect, useImperativeHandle, forwardRef } from "react";
import { useControl, Source, Layer } from "react-map-gl/maplibre";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { DragCircleMode, DirectMode, SimpleSelectMode } from "maplibre-gl-draw-circle";
import AssistRectangle from "@geostarters/mapbox-gl-draw-rectangle-assisted-mode";
import FreehandPolygon from "mapbox-gl-draw-freehand-mode";
import simplify from "@turf/simplify";
import union from "@turf/union";
import difference from "@turf/difference";

FreehandPolygon.simplify = function (polygon) {
    const tolerance = 1 / 100000;
    simplify(polygon, {
        mutate: true,
        tolerance: tolerance,
        highQuality: true
    });
};

const STATIC_FILL_STYLE = {
    id: "static_data_fill",
    type: "fill",
    paint: {
        "fill-color": "#3bb2d0",
        "fill-outline-color": "#3bb2d0",
        "fill-opacity": 0.1
    }
},
    STATIC_LINE_STYLE = {
        id: "static_data_line",
        type: "line",

        layout: {
            "line-cap": "round",
            "line-join": "round"
        },
        paint: {
            "line-color": "#3bb2d0",
            "line-width": 3
        }
    };

export function reduceFeatures(data) {
    if (Array.isArray(data)) {
        data = { type: "FeatureCollection", features: data };
    } else if (data.type === "Feature") {
        data = { type: "FeatureCollection", features: [data] };
    }
    if (data.features.length === 1 && data.features[0].geometry.type === "MultiPolygon") {
        const feature = data.features[0];
        data = {
            ...data,
            features: feature.geometry.coordinates.map(polygon => ({
                ...feature,
                geometry: { type: "Polygon", coordinates: JSON.parse(JSON.stringify(polygon)) }
            }))
        };
    }
    if (data.features.length >= 2) {
        const feature = data.features.reduce(union);
        feature.id = 1;
        data = { ...data, features: [feature] };
    }
    return data;
}

export function reduceFeature(data) {
    data = reduceFeatures(data);
    return data.features[0] || null;
}

const Draw = forwardRef((props, ref) => {
    if (props.flags.select_clu) {
        if (props.data) {
            return (
                <Source id="static_data" type="geojson" data={props.data}>
                    <Layer {...STATIC_FILL_STYLE} />
                    <Layer {...STATIC_LINE_STYLE} />
                </Source>
            );
        } else {
            return null;
        }
    } else {
        return <ActiveDraw ref={ref} {...props} />;
    }
});

const ActiveDraw = forwardRef(({ mode, onSetMode, data, onSetData, flags, onToggleFlag }, ref) => {
    const internalRef = useRef({}),
        handleMode = useCallback(({ mode }) => {
            internalRef.current.onSetMode(mode);
        }, []),
        handleUpdate = useCallback(() => {
            const data = internalRef.current.draw.getAll();
            if (data.features.length === 2 && internalRef.current.flags.cut) {
                const feature = difference(data.features[0], data.features[1]);
                feature.id = 1;
                data.features = [feature];
                internalRef.current.onToggleFlag("cut");
            }
            internalRef.current.onSetData(reduceFeatures(data));
        }, []);

    useEffect(() => {
        internalRef.current.onSetMode = onSetMode;
        internalRef.current.onSetData = onSetData;
        internalRef.current.flags = flags;
        internalRef.current.onToggleFlag = onToggleFlag;
    }, [onSetMode, onSetData, flags, onToggleFlag]);

    const draw = useControl(
        props =>
            new MapboxDraw({
                ...props,
                boxSelect: false,
                modes: {
                    ...MapboxDraw.modes,
                    drag_circle: DragCircleMode,
                    direct_select: DirectMode,
                    simple_select: SimpleSelectMode,
                    freehand_polygon: FreehandPolygon,
                    assist_rectangle: AssistRectangle
                },
                displayControlsDefault: false
            }),
        ({ map }) => {
            map.on("draw.modechange", handleMode);
            map.on("draw.create", handleUpdate);
            map.on("draw.update", handleUpdate);
            map.on("draw.delete", handleUpdate);
        },
        ({ map }) => {
            map.off("draw.modechange", handleMode);
            map.off("draw.create", handleUpdate);
            map.off("draw.update", handleUpdate);
            map.off("draw.delete", handleUpdate);
        },
        {}
    );

    useEffect(() => {
        internalRef.current.draw = draw;
        if (draw && mode) {
            if (mode === "delete") {
                draw.trash();
                onSetMode(null);
            } else if (mode !== draw.getMode()) {
                draw.changeMode(mode);
            }
        }
    }, [draw, mode, onSetMode]);

    useEffect(() => {
        if (draw && data) {
            draw.set(data);
        }
    }, [draw, data]);

    useEffect(() => {
        if (internalRef.current.select_clu === flags.select_clu) {
            return;
        }
        const draw = internalRef.current.draw;
        internalRef.current.select_clu = flags.select_clu;
        if (flags.select_clu) {
            draw.changeMode("simple_select");
        } else {
            const data = draw.getAll();
            if (data.features && data.features.length > 0 && data.features[0].id) {
                draw.changeMode("direct_select", { featureId: data.features[0].id });
            }
        }
    }, [flags.select_clu]);

    useImperativeHandle(ref, () => ({
        getDrawControl: () => internalRef.current.draw
    }));

    return null;
});

Draw.displayName = 'Draw';

ActiveDraw.displayName = 'ActiveDraw';

export default Draw;