import React, { useState, createContext } from "react";
import { Collapse, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";

import covertermination from "./types/CoverTermination.jsx";
import cropprotectantspraying from "./types/CropProtectantSpraying.jsx";
import harvest from "./types/Harvest.jsx";
import nutrientcommercial from "./types/NutrientCommercial.jsx";
import nutrientgypsum from "./types/NutrientGypsum.jsx";
import nutrientlime from "./types/NutrientLime.jsx";
import nutrientmanure from "./types/NutrientManure.jsx";
import nutrientother from "./types/NutrientOther.jsx";
import plantingcash from "./types/PlantingCash.jsx";
import plantingcover from "./types/PlantingCover.jsx";
import residuebaling from "./types/ResidueBaling.jsx";
import residueburning from "./types/ResidueBurning.jsx";
import residuecutting from "./types/ResidueCutting.jsx";
import residuegrazing from "./types/ResidueGrazing.jsx";
import tillage from "./types/Tillage.jsx";

import ActionMenu from "../../common/ActionMenu.jsx";
import { ExpandMore, NavigateNext } from "@mui/icons-material";

export const ActivityContext = createContext({});

export default function ActivityList({ children }) {
    const [isListHovered, setIsListHovered] = useState(false);

    const [isActivityExpanded, setIsActivityExpanded] = useState(false);

    const [activities, setActivities] = useState([]);

    const addActivity = (type, event) => {
        event.stopPropagation();
        setIsActivityExpanded(true);
        setActivities([...activities, { type: type, id: activities.length + 1 }]);
    };

    const removeActivity = id => {
        setActivities(activities.filter(activity => activity.id != id));
    };

    const expandActivity = e => {
        setIsActivityExpanded(true);
    };

    const collapseActivity = e => {
        setIsActivityExpanded(false);
    };

    return (
        <>
            <Grid container>
                <ListItem
                    onMouseEnter={() => setIsListHovered(true)}
                    onMouseLeave={() => setIsListHovered(false)}
                    onClick={isActivityExpanded ? collapseActivity : expandActivity}
                    style={{
                        paddingLeft: "1%",
                        paddingBottom: "0px",
                        cursor: "pointer",
                        backgroundColor: isListHovered ? "rgba(0, 0, 0, 0.04)" : "unset"
                    }}>
                    {isActivityExpanded ? (
                        <ExpandMore sx={{ color: "black" }} />
                    ) : (
                        <NavigateNext sx={{ color: "black" }} />
                    )}
                    <ListItemText>
                        <Typography sx={{ color: "black" }}>ACTIVITIES:</Typography>
                    </ListItemText>
                    <ActionMenu
                        title="ADD NEW"
                        variant="contained"
                        style={{
                            backgroundColor: "#6d8d22",
                            color: "white",
                            height: "30px",
                            minWidth: "110px",
                            float: "right"
                        }}
                        actions={[
                            { name: "Planting - Cash Crop", onClick: e => addActivity("plantingcash", e) },
                            { name: "Planting - Cover Crop", onClick: e => addActivity("plantingcover", e) },
                            { name: "Tillage", onClick: e => addActivity("tillage", e) },
                            {
                                name: "Crop Protectant / Spraying",
                                onClick: e => addActivity("cropprotectantspraying", e)
                            },
                            {
                                name: "Nutrient Application - Commercial",
                                onClick: e => addActivity("nutrientcommercial", e)
                            },
                            { name: "Nutrient Application - Manure", onClick: e => addActivity("nutrientmanure", e) },
                            { name: "Nutrient Application - Lime", onClick: e => addActivity("nutrientlime", e) },
                            { name: "Nutrient Application - Gypsum", onClick: e => addActivity("nutrientgypsum", e) },
                            {
                                name: "Nutrient Application - Other Organic Amendments",
                                onClick: e => addActivity("nutrientother", e)
                            },
                            { name: "Residue Management - Burning", onClick: e => addActivity("residueburning", e) },
                            { name: "Residue Management - Baling", onClick: e => addActivity("residuebaling", e) },
                            { name: "Residue Management - Grazing", onClick: e => addActivity("residuegrazing", e) },
                            { name: "Residue Management - Cutting", onClick: e => addActivity("residuecutting", e) },
                            { name: "Cover Crop Termination", onClick: e => addActivity("covertermination", e) },
                            { name: "Harvest", onClick: e => addActivity("harvest", e) }
                        ]}
                    />
                </ListItem>
            </Grid>
            <Collapse in={isActivityExpanded} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                <List>
                    {activities.map(activity => (
                        <ActivityContext.Provider
                            key={activity.id}
                            value={{ activity, removeActivity: () => removeActivity(activity.id) }}>
                            <Activity type={activity.type} />
                        </ActivityContext.Provider>
                    ))}
                    {activities.length === 0 ? (
                        <ListItem>
                            <Typography>There are currently no activities!</Typography>
                        </ListItem>
                    ) : (
                        <></>
                    )}
                </List>
            </Collapse>
        </>
    );
}

const activityTypes = {
    plantingcash,
    plantingcover,
    tillage,
    cropprotectantspraying,
    nutrientcommercial,
    nutrientmanure,
    nutrientlime,
    nutrientgypsum,
    nutrientother,
    residueburning,
    residuebaling,
    residuegrazing,
    residuecutting,
    covertermination,
    harvest
};

export function Activity({ type }) {
    const Component = activityTypes[type];
    return <Component />;
}
