import {
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Dialog,
    Typography,
    Grid,
    Badge,
    DialogActions,
    Button,
    DialogContent,
    DialogTitle,
    Box
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import React, { useState, useContext } from "react";
import { Close, Delete } from "@mui/icons-material";
import { Form } from "react-form";
import { format, parseISO } from "date-fns";
import { ActivityContext } from "./ActivityList";

export default function ActivityDetail({ summary, children, onSubmit, icon, values, type }) {
    const [open, setOpen] = useState(false);
    const openPopup = () => setOpen(true);
    const closePopup = () => setOpen(false);

    const [cancelOpen, setCancelOpen] = useState(false);
    const openCancel = () => setCancelOpen(true);
    const closeCancel = () => setCancelOpen(false);

    const { removeActivity } = useContext(ActivityContext);

    const handleSubmit = newSubmit => {
        onSubmit(newSubmit);
        closePopup();
    };

    return (
        <>
            <ListItem>
                <Grid container paddingBottom={3} spacing={1}>
                    <Grid item md={1.5} sm={12} xs={12}>
                        <Badge color="secondary" style={{ paddingRight: "5px", borderRadius: "2px" }}>
                            <Typography
                                style={{
                                    fontSize: "12px",
                                    color: "#4e4e4e",
                                    backgroundColor: "#e8ebed",
                                    padding: "3px",
                                    marginBottom: "3px"
                                }}>
                                {formatDate(values.date) ? formatDate(values.date) : "No date set!"}
                            </Typography>
                        </Badge>
                    </Grid>
                    <Grid item md={10.5} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ListItemText>
                                    <Grid container gap={0.5}>
                                        <Grid item>{icon}</Grid>
                                        <Grid item>
                                            <b>{type}</b>
                                        </Grid>
                                    </Grid>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton onClick={openPopup}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={removeActivity}>
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </Grid>
                            {summary}
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
            <Dialog open={open} onClose={closePopup}>
                <Form defaultValues={values} onSubmit={handleSubmit}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <DialogTitle
                                display="flex"
                                gap={1}
                                alignItems="center"
                                sx={theme => ({
                                    backgroundColor: theme.palette.primary.main,
                                    color: "white"
                                })}>
                                {icon}
                                {type}
                                <Box flex="1" />
                                <IconButton
                                    onClick={closePopup}
                                    sx={{
                                        color: "white"
                                    }}>
                                    <Close />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>{children}</DialogContent>
                            <DialogActions>
                                <Button onClick={openCancel} variant="outlined">
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained">
                                    Save
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </Dialog>
            <Dialog open={cancelOpen}>
                <DialogTitle display="flex" gap={1} alignItems="center">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <Typography>Are you sure? Your changes will be lost!</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        onClick={() => {
                            setCancelOpen(false);
                            setOpen(false);
                        }}
                        variant="outlined">
                        Close Without Saving
                    </Button>
                    <Button size="small" onClick={closeCancel} variant="contained">
                        Continue Editing
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function formatDate(dateString) {
    if (!dateString) {
        return "";
    }
    var date = parseISO(dateString);
    return format(date, "LLL d, y");
}
