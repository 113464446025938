import { FormControlLabel, Grid, ListSubheader, MenuItem, Radio, Typography } from "@mui/material";
import ActivityDetail from "../ActivityDetail";
import React, { useState } from "react";
import { Yard } from "@mui/icons-material";
import TextField from "../common/TextField";
import Select from "../common/Select";
import RadioGroup from "../common/RadioGroup";
import Autocomplete from "../common/Autocomplete";

export default function PlantingCash() {
    // menuitem style
    const mi = {
        paddingLeft: "32px"
    };

    // subheader style
    const sh = {
        fontWeight: "bold",
        fontSize: "1.15em",
        lineHeight: 2
    };

    const [values, setValues] = useState({ crop: "Barley", operation: 2, seeding_rate: 3200 });
    const handleSubmit = data => {
        console.log(data);
        setValues(data);
    };

    return (
        <ActivityDetail
            values={values}
            icon={<Yard />}
            type="Planting - Cash Crop"
            summary={
                <>
                    <Grid item sm={4} xs={12}>
                        <Typography style={{ fontSize: "14px" }}>EQUIPMENT / OPERATION TYPE</Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>{values.operation}</Typography>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Typography style={{ fontSize: "14px" }}>CROP</Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>{values.crop}</Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography style={{ fontSize: "14px" }}>SEEDING RATE</Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                            {values.seeding_rate} seeds/ac
                        </Typography>
                    </Grid>
                </>
            }
            onSubmit={handleSubmit}>
            <Grid container paddingTop={2} spacing={1}>
                <Grid item xs={5}>
                    <Typography>Activity Date</Typography>
                    <TextField field="date" type="date" size="small" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={7}>
                    <Typography>Crop</Typography>
                    <Autocomplete
                        field="crop"
                        size="small"
                        options={[
                            "Alfalfa",
                            "Barley",
                            "Chickpeas (garbanzos)",
                            "Corn (grain)",
                            "Corn (silage)",
                            "Cotton",
                            "Dry Beans",
                            "Dry Peas",
                            "Fava Beans",
                            "Lentils",
                            "Lupin",
                            "Peanuts",
                            "Potatoes",
                            "Rice",
                            "Sorghum",
                            "Soybeans",
                            "Sugar beats",
                            "Wheat (durum)",
                            "Wheat (spring)",
                            "Wheat (winter)"
                        ]}
                        renderInput={params => <TextField {...params} />}
                    />
                </Grid>
                <Grid item sm={8} xs={12}>
                    <Typography>Operation</Typography>
                    <Select size="small" fullWidth field="operation" sx={{ width: "325px" }}>
                        <ListSubheader style={sh}>Planter</ListSubheader>
                        <MenuItem style={mi} value={1}>
                            Strip till
                        </MenuItem>
                        <MenuItem style={mi} value={2}>
                            Strip till, subsoiler
                        </MenuItem>
                        <MenuItem style={mi} value={3}>
                            Double disk opener, 15 inch row spacing
                        </MenuItem>
                        <MenuItem style={mi} value={4}>
                            Relay or interseed
                        </MenuItem>
                        <MenuItem style={mi} value={5}>
                            Ridge till
                        </MenuItem>
                        <MenuItem style={mi} value={6}>
                            Bedder, hipper, hiller, 6 inch beds
                        </MenuItem>
                        <ListSubheader style={sh}>Seeding</ListSubheader>
                        <MenuItem style={mi} value={7}>
                            Aerial, broadcast
                        </MenuItem>
                        <MenuItem style={mi} value={8}>
                            Aerial, relay or interseed
                        </MenuItem>
                        <MenuItem style={mi} value={9}>
                            Ground, broadcast
                        </MenuItem>
                        <ListSubheader style={sh}>Drill or air seeder</ListSubheader>
                        <MenuItem style={mi} value={10}>
                            Double disk opener, fert openers
                        </MenuItem>
                        <MenuItem style={mi} value={11}>
                            Hoe opener in heavy residue, fert openers
                        </MenuItem>
                        <MenuItem style={mi} value={12}>
                            Double disk
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <Typography>Seeding Rate</Typography>
                    <TextField
                        size="small"
                        type="number"
                        units="seeds/ac"
                        inputProps={{
                            step: 0.1
                        }}
                        field="seeding_rate"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>Seed Treatment</Typography>
                    <RadioGroup row field="seed_treatment">
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
            </Grid>
        </ActivityDetail>
    );
}
