import zIndex from '@mui/material/styles/zIndex';
import React, { Component } from 'react';


class ActionDialog extends Component {

    handleDismiss = () => {
        if (this.props.onDismiss) {
            this.props.onDismiss();
        }
    };

    handleProceed = () => {
        if (this.props.onProceed) {
            this.props.onProceed();
        }
    };

    render() {
        const { isOpen, message } = this.props;

        if (!isOpen) {
            return null;
        }

        const styles = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1001
            },
            dialog: {
                background: 'white',
                padding: '20px',
                borderRadius: '5px',
                boxShadow: '0px 2px 10px 3px rgba(0, 0, 0, 0.1)',
                marginBottom: '10px'
            },
            header: {
                marginBottom: '10px'
            },
            body: {
                marginBottom: '20px'
            },
            footer: {
                display: 'flex',
                justifyContent: 'flex-end'
            },
            button_proceed: {
                marginLeft: '10px',
                padding: '5px 10px',
                border: 'none',
                borderRadius: '3px',
                cursor: 'pointer',
                background: '#6d8d22',
                color: 'white'
            },
            button_dismiss: {
                marginLeft: '10px',
                padding: '5px 10px',
                border: 'none',
                borderRadius: '3px',
                cursor: 'pointer',
                background: '#f7f6f6',
                color: '#666666'
            }
        };

        return (
            <div >
                <div style={styles.dialog}>
                    {/* <div style={styles.header}>
                        <h2>{title}</h2>
                    </div> */}
                    <div style={styles.body}>
                        <p>{message}</p>
                    </div>
                    <div style={styles.footer}>
                        <button
                            style={styles.button_proceed}
                            onMouseOver={(e) => (e.target.style.background = '#a1c350')}
                            onMouseOut={(e) => (e.target.style.background = styles.button_proceed.background)}
                            onClick={this.handleProceed}
                        >
                            Proceed
                        </button>
                        <button
                            style={styles.button_dismiss}
                            onMouseOver={(e) => (e.target.style.background = '#ebebeb')}
                            onMouseOut={(e) => (e.target.style.background = styles.button_dismiss.background)}
                            onClick={this.handleDismiss}
                        >
                            Dismiss
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ActionDialog;