import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import ResearchDialog from "../research/ResearchDialog";
import * as navActions from "../common/actions";
import Insights from "./Insights";
import NewsUpdates from "./NewsUpdates";
import { Stepper, Step, StepLabel, StepButton } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';



const styles = theme => ({
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px",
        float: "right"
    },
    stepRoot: {
        backgroundColor: "#f2f2f2",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        marginTop: "-20px"
    },
    active: {
        "& .MuiStepLabel-label.Mui-active": {
            color: "#6d8d22",
        }
    },
});

class InsightsDashboard extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    state = {
        activeIndex: 0,
        dashboardTitle: "Welcome!"
    };

    handleStep = step => {
        this.setState({
            activeIndex: step,
            dashboardTitle: step === 0 ? "Welcome!" : step === 1 ? "Welcome!" : ""
        });
    };

    render() {
        const { classes } = this.props;
        const { activeIndex, dashboardTitle } = this.state;

        return (

            <AppContainer
                authenticated
                background={"#f2f2f2"}
                header={
                    <>
                        <PageHeader title={dashboardTitle} color="betadashboard" />

                        <ResearchDialog />
                    </>
                }>

                <Stepper nonLinear className={classes.stepRoot} activeStep={activeIndex}>
                    <Step key={"operation"} className={`${classes.pointer} ${classes.active}`}>
                        <StepButton
                            onClick={() => this.handleStep(0)}
                            completed={false}
                            icon={<HomeIcon className={classes.iconSize} />}
                            style={{
                                textDecoration: activeIndex === 0 ? "underline" : "none",
                                textDecorationThickness: activeIndex === 0 ? "2px" : "none",
                                textUnderlineOffset: activeIndex === 0 ? "5px" : "none",
                                color: activeIndex === 0 ? "#6d8d22" : "#434343"
                            }}>
                            <StepLabel>
                                Home
                            </StepLabel>
                        </StepButton>
                    </Step>
                    <Step key={"news"} className={`${classes.pointer} ${classes.active}`}>
                        <StepButton
                            onClick={() => this.handleStep(1)}
                            completed={false}
                            icon={<BarChartIcon className={classes.iconSize} />}
                            style={{
                                textDecoration: activeIndex === 1 ? "underline" : "none",
                                textDecorationThickness: activeIndex === 1 ? "2px" : "none",
                                textUnderlineOffset: activeIndex === 1 ? "5px" : "none",
                                color: activeIndex === 1 ? "#6d8d22" : "#434343"
                            }}>
                            <StepLabel>
                                Operations Snapshot
                            </StepLabel>
                        </StepButton>
                    </Step>
                </Stepper>

                <Grid container spacing={16} style={{ marginLeft: "5px" }}>
                    {(activeIndex === 0 || !activeIndex) && <NewsUpdates />}
                    {activeIndex === 1 && <Insights />}
                </Grid>
            </AppContainer>

        );
    }
}

InsightsDashboard = connect(
    state => ({
        navState: state.nav
    }),
    navActions
)(InsightsDashboard);

export default withStyles(styles)(InsightsDashboard);
