import React from "react";
import { Dialog, DialogActions, DialogContent, TextField, Button, Checkbox } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "../common/TypographyWrapper";
import Close from "@mui/icons-material/CloseOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { Project, ProjectUser } from "../project/models";
import { ProjectRotationSystem } from "../rotation/models";

const cancelButtonStyle = {
    color: '#6d8d22',
    borderColor: '#6d8d22',
    backgroundColor: 'white',
    border: '1px solid',
    height: '35px',
    borderRadius: '3px'
};

const confirmButtonStyle = {
    color: 'white',
    backgroundColor: '#6d8d22',
    height: '35px',
    borderRadius: '3px'
};


class ProjectEnrollDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectCode: "",
            view: 0,
            checked: false,
            loading: false,
            message: null,
            project: null,
        };

    }

    handleValidate = () => {

        const pOpt = this;

        const values = { code: this.state.projectCode };

        this.props.checkCode(values, function (state) {
            if (state.pending) {
                pOpt.setState({ loading: true, message: null, project: null });
            } else {
                if (state.success && state.result.length > 0) {
                    pOpt.setState({ loading: false, message: null, project: state.result[0], view: 1 });
                } else {
                    pOpt.setState({
                        loading: false,
                        message: "Project not found or you are already a member of that project",
                        project: null,
                        view: 0
                    });
                }
            }
        });
    };

    optIn = () => {
        const { ormProjectUserCreate, ormProjectCreateLocalOnly, authState } =
            this.props;
        const { project } = this.state;
        ormProjectUserCreate({
            project: project.id,
            user: authState.user.id
        });
        ormProjectCreateLocalOnly(project);
    };

    handleProjectOptIn = () => {
        if (this.state.checked) {
            this.optIn();

            this.setState({ view: 2 });
        }
    };

    handleEnrollCropYear = () => {
        if (this.state.checked) {
            this.props.handlePopupClickClose()
            this.setState({ projectCode: "", view: 0, checked: false });
        }
    };

    handleChange = (event) => {
        this.setState({ projectCode: event.target.value, message: null });
    };

    handleCheck = (event) => {
        this.setState({ checked: event.target.checked });
    };

    handleClose = () => {
        this.props.handlePopupClickClose();
        this.setState({ projectCode: "", view: 0, checked: false });
    }

    render() {

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="form-dialog-title"
                style={{ textAlign: "center", minHeight: "300px" }}
                fullWidth
                maxWidth="xs"
            >
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: "#f15d22", minHeight: 50 }}>
                    <Typography variant="h6">

                    </Typography>
                    <Tooltip title="Close">
                        <IconButton size="large" style={{ color: "white" }} onClick={() => this.props.handlePopupClickClose()} >
                            <Close color="white" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <DialogContent>
                    {this.state.view === 0 &&
                        <div>
                            <Typography variant="title" style={{ fontWeight: "bold", paddingBottom: "15px" }}>
                                Select a Fieldprint Project
                            </Typography>
                            <Typography variant="h5" style={{ paddingBottom: "10px" }}>
                                You are not participating in any Fieldprint Project. To
                                get started, you will need an opt-in code for your project. If you have one already, enter it below. If not, please contact your project administrator.
                            </Typography>

                            <TextField
                                id="projectCode"
                                label="Project Code"
                                variant="filled"
                                InputProps={{ style: { fontSize: "12px" } }}
                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                style={{ width: "100px", height: "25px" }}
                                size="small"
                                value={this.state.projectCode}
                                onChange={this.handleChange}
                            >
                            </TextField>

                            {this.state.message && <Typography variant="h5" style={{ paddingTop: "30px", color: "red" }}>
                                {/* {this.state.message} */}
                                That project code was not found. Please try again or contact your Project Administrator.
                            </Typography>}
                        </div>}
                    {this.state.view === 1 &&
                        <div>
                            <Typography variant="title" style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                                Select a Fieldprint Project
                            </Typography>
                            <Typography variant="h6" style={{ paddingBottom: "10px", color: "green" }}>
                                {this.state.project != null ? this.state.project.name : "Name Unavailable"}
                            </Typography>
                            <Typography variant="h6" style={{ paddingBottom: "10px", color: "grey" }}>
                                PLEASE READ
                            </Typography>
                            <Typography style={{ paddingBottom: "10px", color: "grey", fontSize: "12px" }}>
                                We want users to fully understand when Platform options impact data
                                privacy. As noted in the <a href="/#" target="_blank">Data and Privacy Policy</a>, associating fields with
                                a FIeldprint Project means your Fieldprint Data and Fieldprint Results
                                can be seen and analyzed by Project Participants (Owners, Sponsors,
                                Partners and Implementation Partners). Your data will be used to
                                generate aggregated and anonymized reports for organizational
                                reporting and/or Project Claims and Verification. Field associations can
                                be removed, or you can fully Opt-Out of a Project at any time. However,
                                once data has been aggregated and anonymized, it cannot be deleted.
                            </Typography>
                            <Checkbox
                                checked={this.state.checked}
                                onChange={this.handleCheck}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <Typography style={{ color: "black", fontSize: "12px" }}>
                                By checking the box you acknowledge that you have read the above
                                terms and have reviewed the Data and Privacy Policy.
                            </Typography>
                        </div>
                    }
                    {this.state.view === 2 &&
                        <div>
                            <Typography variant="title" style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                                Select a Fieldprint Project
                            </Typography>
                            <Typography variant="h6" style={{ paddingBottom: "10px", color: "green" }}>
                                {this.state.project != null ? this.state.project.name : "Name Unavailable"}
                            </Typography>
                            <CheckCircleIcon style={{ color: "#f15d22", height: "40px", width: "40px" }} />
                            <Typography style={{ color: "black", fontSize: "12px" }}>
                                You have now signed up for a project!
                            </Typography>
                        </div>
                    }

                </DialogContent>

                <DialogActions style={{ justifyContent: 'center', paddingTop: '8px', marginBottom: '20px' }}>
                    <Button style={cancelButtonStyle} onClick={() => this.handleClose()}>Cancel</Button>
                    {this.state.view === 0 && <Button style={confirmButtonStyle} onClick={() => this.handleValidate()}>Next</Button>}
                    {this.state.view === 1 && <Button style={!this.state.checked ? cancelButtonStyle : confirmButtonStyle} disabled={!this.state.checked} onClick={() => this.handleProjectOptIn()}>Next</Button>}
                    {this.state.view === 2 && <Button style={!this.state.checked ? cancelButtonStyle : confirmButtonStyle} disabled={!this.state.checked} onClick={() => this.handleEnrollCropYear()}>Finish</Button>}
                </DialogActions>
            </Dialog>
        )
    }
}

ProjectEnrollDialog = connect(
    state => ({
        authState: state.auth
    }),
    {
        ...Project.actions,
        ...ProjectUser.actions,
        ...ProjectRotationSystem.actions
    }
)(ProjectEnrollDialog)


export default ProjectEnrollDialog;