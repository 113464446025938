import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../common/withRouter";
import Prompt from "../../common/Prompt";
import { connect } from "react-redux";

import Grid from "../../common/GridWrapper";
import { Stepper, Step, StepLabel, StepButton } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ApplyRotationSystem from "./1_ApplyRotationSystem";
import ManagementQuestions from "./2_ManagementQuestions";
import FieldActivities from "./3_FieldActivities";
import Review from "./4_Review";
import CropHistory from "./5_CropHistory";
import Facts from "../Facts";
import { CropYear, CROP_YEAR_COMPLETED, FieldActivity } from "./models";
import { Field } from "../models";
import { Fertilizer } from "./activities/models";
import AppContainer from "../../common/AppContainer";
import PageHeader from "../../common/PageHeader";
import * as navActions from "../../common/actions";
import { getValue } from "../../../api/utils";

const getCropYear = CropYear.selectByUrlId(cropYear => ({
    crop_name: cropYear.crop.name,
    crop_crcrops: cropYear.crop.crcrops,
    crop_yield_unit: cropYear.crop.yield_unit,
    farm_id: cropYear.field.farm.id,
    farm_name: cropYear.field.farm.name,
    field_name: cropYear.field._fields.name,
    field_id: cropYear.field._fields.id,
    fieldObject: cropYear.field._fields,
    formData: cropYear.ref,
    activities: cropYear.activities.all().toModelArray(),
    ...cropYear.ref
}));

const styles = theme => ({
    stepRoot: {
        backgroundColor: "#eeeeee",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1)
    },
    pointer: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        "&:hover": {
            borderBottom: "8px solid lightgray"
        }
    },
    stepColor: {
        "& svg path": {
            color: theme.palette.secondary.main // + " !important",
        }
    },
    lessMargin: {
        "& span": {
            marginTop: "8px !important"
        }
    },
    embedNumber: {
        left: "52%",
        position: "absolute",
        zIndex: 1299,
        top: "-10%",
        borderRadius: "50%",
        width: "12px",
        height: "12px",
        padding: "1px",
        background: "#ff0000",
        border: "1px solid #fff",
        textAlign: "center",
        color: "#fff",
        fontSize: 10,
        fontWeight: 700
    },
    linkColor: {
        color: "#808080"
    },
    crumbColor: {
        color: theme.palette.primary.main
    }
});

const STEPS = ["Rotation", "Management", "Operations", "Crop History", "Review"];

class CropYearDetail extends Component {
    state = {
        unsavedFields: false
    };

    componentDidMount() {
        this.loadCropYear({});
    }

    componentDidUpdate(prevProps) {
        this.loadCropYear(prevProps);
    }

    loadCropYear(prevProps) {
        const {
                cropYear,
                ormCropYearLoadDetail,
                ormFieldActivityLoadDetail,
                ormFertilizerLoadDetail,
                syncState,
                authState
            } = this.props,
            { cropYear: lastYear, syncState: lastSyncState } = prevProps;

        if (!cropYear || (authState.user && authState.user.guest)) {
            return;
        }
        if (!lastYear || lastYear.id !== cropYear.id || !lastSyncState.ready) {
            if (syncState.ready && !getValue(cropYear, "synced")) {
                ormCropYearLoadDetail(cropYear.id);

                if (cropYear.activities) {
                    cropYear.activities.forEach(function (activity) {
                        ormFieldActivityLoadDetail(activity.id);

                        activity.fertilizers.toModelArray().forEach(function (fertilizer) {
                            ormFertilizerLoadDetail(fertilizer.id);
                        });
                    });
                }
            }
        }
    }

    /* Stepper Events */
    totalSteps = () => {
        return STEPS.length;
    };

    isLastStep(step) {
        return step === this.totalSteps() - 1;
    }

    handleNext = () => {
        let { activeStep, field_id } = this.props.cropYear;
        if (this.isLastStep(activeStep)) {
            this.props.history.push("/field/" + field_id);
            //activeStep = 0;
        } else {
            activeStep = (activeStep || 0) + 1;
        }
        this.handleStep(activeStep);
    };

    returnFarm = () => {
        let { farm_id } = this.props.cropYear;
        this.props.history.push("/farm/" + farm_id);
    };

    returnField = () => {
        let { field_id } = this.props.cropYear;
        this.props.history.push("/field/" + field_id);
    };

    handleUnsavedFields = unsavedFields => {
        this.setState({ unsavedFields });
    };

    handleStep = (step, fromStepper) => {
        const { id } = this.props.cropYear;

        this.handleUnsavedFields(false);

        // Hacky method to save form when using stepper
        // This doesn't work on the activity subforms (maybe it could...)
        if (fromStepper) {
            // Apparently just having a single "activeForm" works because only one stepper detail can be active at once
            if (this.activeForm) {
                // Pass true to the submit function
                // Seems to come out as the second argument in onSubmit
                // Don't do the handleNext's if integer (normally an event) - set activeStep to this number
                this.activeForm.setState({ submitClicked: true });
                const f = this;
                setTimeout(function () {
                    // The state is not set right away...
                    f.activeForm.form.submitForm(step);
                }, 50);
            } else if (this.activeSave) {
                // Activity
                this.activeSave(false, step);
            } else {
                // Review
                this.props.ormCropYearUpdateLocalOnly({
                    id: id,
                    activeStep: step
                });
            }
        } else {
            this.props.ormCropYearUpdateLocalOnly({
                id: id,
                activeStep: step
            });
        }
    };

    render() {
        const {
            classes,
            cropYear,
            ormCropYearUpdate,
            history,
            navSelectPage,
            ormCropYearUpdateLocalOnly,
            ormFieldUpdate,
            authState
        } = this.props;
        const { unsavedFields } = this.state;
        var synced;
        if (authState.user && authState.user.guest) synced = true;
        else synced = getValue(cropYear, "synced");

        const fieldTitle = cropYear.field_name || cropYear.field_id,
            cropYearTitle = `${cropYear.year} ${cropYear.crop_name}`;

        return (
            <AppContainer
                authenticated
                synced={!synced}
                handleUnsavedFields={this.handleUnsavedFields}
                header={
                    <>
                        <PageHeader
                            color="field"
                            title={fieldTitle}
                            pageTitle={`${cropYearTitle} on ${fieldTitle}`}
                            crumbs={
                                <div>
                                    <Link
                                        className={classes.linkColor}
                                        onClick={() => navSelectPage("farm_" + cropYear.farm_id)}
                                        to={"/farm/" + cropYear.farm_id + "/edit"}>
                                        {cropYear.farm_name || "New Farm"}
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;
                                    <Link className={classes.linkColor} to={"/field/" + cropYear.field_id}>
                                        {fieldTitle}
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                                    <span className={classes.crumbColor}>Crop Year: {cropYearTitle}</span>
                                </div>
                            }
                        />
                        <Facts field={cropYear.fieldObject} />
                    </>
                }>
                <Prompt
                    when={unsavedFields}
                    message="You have entered data that has not been saved in our system yet. Do you want to leave without finishing?"
                />
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Stepper
                            nonLinear
                            className={classes.stepRoot}
                            alternativeLabel
                            activeStep={cropYear.activeStep || 0}>
                            {STEPS.map((label, index) => {
                                return (
                                    <Step key={label} className={classes.pointer}>
                                        {CROP_YEAR_COMPLETED(cropYear, index, true) ? (
                                            <div />
                                        ) : (
                                            <div className={classes.embedNumber}>!</div>
                                        )}
                                        <StepButton
                                            onClick={() => this.handleStep(index, true)}
                                            completed={CROP_YEAR_COMPLETED(cropYear, index)}>
                                            <StepLabel
                                                classes={{
                                                    labelContainer: classes.lessMargin,
                                                    iconContainer: classes.stepColor
                                                }}>
                                                {label}
                                            </StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div>
                            {(cropYear.activeStep === 0 || !cropYear.activeStep) && (
                                <ApplyRotationSystem
                                    onRef={el => (this.activeForm = el)}
                                    cropYear={cropYear}
                                    handleNext={this.handleNext}
                                    fieldName={cropYear.field_name || cropYear.field_id}
                                    fieldId={cropYear.field_id}
                                    history={history}
                                    ormCropYearUpdate={ormCropYearUpdate}
                                    ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                />
                            )}
                            {cropYear.activeStep === 1 && (
                                <ManagementQuestions
                                    onRef={el => (this.activeForm = el)}
                                    cropYear={cropYear}
                                    handleNext={this.handleNext}
                                    fieldName={cropYear.field_name || cropYear.field_id}
                                    fieldId={cropYear.field_id}
                                    history={history}
                                    ormCropYearUpdate={ormCropYearUpdate}
                                    ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                />
                            )}
                            {cropYear.activeStep === 2 && (
                                <FieldActivities
                                    onRef={el => (this.activeSave = el)}
                                    cropYear={cropYear}
                                    handleNext={this.handleNext}
                                    fieldName={cropYear.field_name || cropYear.field_id}
                                    fieldId={cropYear.field_id}
                                    history={history}
                                    ormCropYearUpdate={ormCropYearUpdate}
                                    ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                />
                            )}
                            {cropYear.activeStep === 3 && (
                                <CropHistory
                                    onRef={el => (this.activeSave = el)}
                                    cropYear={cropYear}
                                    handleNext={this.handleNext}
                                    fieldName={cropYear.field_name || cropYear.field_id}
                                    ormFieldUpdate={ormFieldUpdate}
                                    history={history}
                                />
                            )}
                            {cropYear.activeStep === 4 && (
                                <Review
                                    onRef={el => (this.activeSave = el)}
                                    cropYear={cropYear}
                                    handleStep={this.handleStep}
                                    fieldName={cropYear.field_name || cropYear.field_id}
                                    fieldId={cropYear.field_id}
                                    history={history}
                                    ormCropYearUpdate={ormCropYearUpdate}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

CropYearDetail = connect(
    (state, ownProps) => ({
        //"field": getField(state, ownProps),
        cropYear: getCropYear(state, ownProps),
        syncState: state.sync,
        authState: state.auth
    }),
    {
        ...CropYear.actions,
        ...FieldActivity.actions,
        ...Fertilizer.actions,
        ...Field.actions,
        ...navActions
    }
)(CropYearDetail);

export default withStyles(styles)(withRouter(CropYearDetail));
