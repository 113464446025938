import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";
import * as navActions from "../common/actions";
import { News, Alerts } from "./models";
import Carousel from "../common/Carousel";
import PlatformUpdates from "./PlatformUpdates";
import AlertUpdates from "./AlertUpdates";
import { Box, Card, CardContent, IconButton } from '@mui/material';
import DateComponent from '../common/DateComponent';
import DvrIcon from '@mui/icons-material/Dvr';
import SchoolIcon from '@mui/icons-material/School';
import LaunchIcon from '@mui/icons-material/Launch';
import { ProjectUser } from "../project/models";

const styles = theme => ({
    grey: {
        backgroundColor: "#eeeeee",
        border: "4px solid #ffffff",
        whiteSpace: "nowrap"
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px"
    },
    centerAlign: {
        textAlign: "center"
    },
    standardMargin: {
        marginLeft: 8,
        marginRight: 8,
        marginTop: 8
    },
    divideButton: {
        marginBottom: 8,
        width: "50%",
        marginTop: 16,
        minWidth: 44
    },
    filterButton: {
        marginBottom: 8,
        width: "100%",
        border: "1px solid #666666"
    },
    buttonSelected: {
        border: "1px solid #00adee",
        minWidth: "44px"
    },
    iconSelected: {
        color: "#00adee"
    },
    mapContainer: {
        height: 600,
        paddingRight: "4px !important",
        paddingLeft: "4px !important"
    },
    smallerIcon: {
        width: ".9em"
    },
    paddingRight: {
        paddingRight: "24px !important"
    },
    paddingRightField: {
        [theme.breakpoints.down("lg")]: {
            paddingRight: "24px !important"
        }
    },
    marker: {
        height: 18,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: -3
    },
    analyticsTooltip: {
        // Tooltips don't work on disabled buttons without div, style div as button
        display: "inline-block",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    },

    // Facts
    hidden: {
        opacity: 0,
        overflow: "hidden",
        padding: "0 !important",
        height: "0 !important"
    },
    panelText: {
        color: "#fff",
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: "#00adee",
        marginLeft: "auto",
        marginRight: "auto",
        width: "155px",
        borderBottomLeftRadius: theme.spacing(3),
        borderBottomRightRadius: theme.spacing(3),
        padding: "0 16px 0 8px"
    },
    panelContainer: {
        padding: "20px",
        height: "130px",
        width: "100%",
        transition: theme.transitions.create(["height", "opacity"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    button: {
        height: "20px",
        width: "20px",
        color: "#fff"
    },
    marginTop: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: "#00adee"
    },
    floatRight: {
        float: "right",
        marginRight: theme.spacing(1)
    },
    iconText: {
        height: 18,
        width: 24,
        marginBottom: -3
    },
    underlineBottom: {
        borderBottom: "1px dotted",
        marginBottom: theme.spacing(2),
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666"
    },
    linkColor: {
        color: "#808080"
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px",
        float: "right"
    },
    card: {
        minWidth: 275,
    },
});

const getNews = News.selectAll();

const getAlerts = Alerts.selectAll();

const getProjectUsers = ProjectUser.selectAll(PU => ({
    project_attributes: PU.project,
    ...PU.ref
}));

const sortAndFilterNews = (news) => {

    let n = {};

    for (var i = 0; i < news.length; i++) {
        news[i].content = news[i].content.replace(/&lt;/g, '<');
        news[i].content = news[i].content.replace(/&gt;/g, '>');
    }

    n.announcements = news.filter(n => n.category === "ANNOUNCEMENTS").sort((a, b) =>
        new Date(b.createdate) - new Date(a.createdate));

    n.updates = news.filter(n => n.category === "UPDATES").sort((a, b) =>
        new Date(b.createdate) - new Date(a.createdate));

    n.resources = news.filter(n => n.category === "RESOURCES").sort((a, b) =>
        new Date(b.createdate) - new Date(a.createdate));

    n.events = news.filter(n => n.category === "EVENTS").sort((a, b) =>
        new Date(b.createdate) - new Date(a.createdate));

    return n;
};


class NewsUpdates extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    filterAlerts() {

        const { alerts, projectUsers } = this.props;

        let a = [];

        var hasSAI = false;

        projectUsers.map(project => {
            if (project.project_attributes !== null && project.project_attributes.has_sai !== null ? project.project_attributes.has_sai : false) {
                hasSAI = true;
            }
        });

        a = alerts.filter(a => a.alertgroup == "ALLUSERS");

        if (hasSAI) {
            let sai = alerts.filter(a => a.alertgroup == "SAI");
            a = [...a, ...sai];
        }

        return a.sort((a, b) =>
            new Date(b.createdate) - new Date(a.createdate));

    }

    render() {

        const { news, classes } = this.props;

        let newsData = sortAndFilterNews(news);

        let alertData = this.filterAlerts();

        return (
            <Grid container spacing={16} style={{ marginTop: "5px" }}>
                <Typography>
                    The Fieldprint® Platform is a pioneering assessment framework that empowers brands, retailers, suppliers and farmers at every stage in their sustainability journey, to measure the environmental impacts of commodity crop production and identify opportunities for continuous improvement. &nbsp;Farmers can access this free and confidential tool through the Fieldprint Calculator here or through associated farm-management software that integrates the Platform’s metrics and algorithms. Brands, retailers and suppliers can access aggregated data from farmers who opt-in to participate in their Fieldprint Projects.
                </Typography>
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <Grid style={{ marginTop: "20px" }}>
                            <Typography style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "20px" }}>Announcements</Typography>
                            <Carousel items={newsData.announcements} />

                        </Grid>
                        <Grid>
                            <Typography style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "20px" }}>Platform Updates</Typography>
                            <PlatformUpdates announcements={newsData.updates} />
                        </Grid>
                        <Grid style={{ marginTop: "20px" }}></Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {alertData.length > 0 && (
                            <Grid style={{ marginTop: "20px" }}>
                                <Typography style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "20px" }}>Alerts</Typography>
                                <AlertUpdates alerts={alertData} />
                            </Grid>
                        )}

                        <Grid style={{ marginTop: "20px" }}>
                            <Typography style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "20px" }}>Events</Typography>
                            <Card className={classes.card}>
                                <CardContent>
                                    {newsData.events.map((n, idx) => {
                                        return (
                                            <Grid key={"event" + idx}>
                                                <Typography style={{ fontWeight: "bold" }}>
                                                    {n.label}
                                                </Typography>
                                                <Typography style={{ fontSize: "small" }}>
                                                    <Box display="flex" alignItems="center">
                                                        <DvrIcon style={{ paddingRight: "3px" }} />
                                                        <DateComponent date={n.createdate} />
                                                    </Box>
                                                </Typography>
                                                <div
                                                    key={idx}
                                                    style={{ fontWeight: "200", fontSize: "small" }}
                                                    dangerouslySetInnerHTML={{ __html: n.content }}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid style={{ marginTop: "20px" }}>
                            <Typography style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "20px" }}>Resources</Typography>
                            <Card className={classes.card}>
                                <CardContent>
                                    {newsData.resources.map((n, idx) => {
                                        return (
                                            <Grid key={"event" + idx} container>
                                                <Grid xs={1}>
                                                    <SchoolIcon style={{ paddingRight: "12px", color: "#434343", marginTop: "8px" }} />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <Box display="flex" alignItems="center">
                                                        <Typography style={{ fontWeight: "bold" }}>{n.label}</Typography>
                                                        <a href={n.link} target="_blank" rel="noopener noreferrer">
                                                            <IconButton>
                                                                <LaunchIcon style={{ paddingLeft: "0px", color: "#b0b0b0" }} />
                                                            </IconButton>
                                                        </a>
                                                    </Box>
                                                    <div
                                                        key={idx}
                                                        style={{ fontWeight: "200", fontSize: "small", marginTop: "-20px" }}
                                                        dangerouslySetInnerHTML={{ __html: n.content }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

NewsUpdates = connect(
    state => ({
        navState: state.nav,
        news: getNews(state),
        alerts: getAlerts(state),
        projectUsers: getProjectUsers(state),
    }),
    navActions
)(NewsUpdates);

export default withStyles(styles)(NewsUpdates);
