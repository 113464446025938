import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircularProgress from "@mui/material/CircularProgress";

export default function ActionElipse({ actions }) {

    const [anchorE1, setAnchorE1] = React.useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorE1(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorE1(null);
    };

    return (
        <>
            <IconButton
                aria-controls="action-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorE1}
                keepMounted
                open={Boolean(anchorE1)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {actions.map((action, index) => (
                    <MenuItem key={index} onClick={action.onClick} disabled={action.disabled} style={action.style} >
                        {action.isLoading ? <><CircularProgress style={{ color: "#7d7e7f", marginRight: "4px" }} size={20} /> Processing...</> : action.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}