import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CircularProgress from "@mui/material/CircularProgress";

export default function ActionMenu({ actions, autoClose, title, style }) {
    const [anchorE1, setAnchorE1] = React.useState(null);

    const handleAction = (event, action) => {
        action.onClick(event);
        if (autoClose || action.autoClose) {
            handleClose();
        }
    };

    const handleClick = event => {
        setAnchorE1(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorE1(null);
    };

    const isOpen = Boolean(anchorE1);

    return (
        <>
            <Button
                endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                aria-controls="action-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={style}>
                {title}
            </Button>
            <Menu
                id="long-menu"
                anchorEl={anchorE1}
                keepMounted
                open={Boolean(anchorE1)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}>
                {actions.map((action, index) => (
                    <MenuItem
                        key={index}
                        onClick={e => handleAction(e, action)}
                        disabled={action.disabled}
                        style={action.style}>
                        {action.isLoading ? (
                            <>
                                <CircularProgress style={{ color: "#7d7e7f", marginRight: "4px" }} size={20} />{" "}
                                {action.loadingMessage != null ? " " + action.loadingMessage : "Processing..."}{" "}
                            </>
                        ) : (
                            action.name
                        )}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
