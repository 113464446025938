import React, { Component, createRef } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../common/withRouter";
import Prompt from "../common/Prompt";
import { TourProvider } from "@reactour/tour";

import Button from "../common/ButtonWrapper";
import Divider from "@mui/material/Divider";
import Typography from "../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import SoilTextureImg from "../../SoilTexture.png";
import CircularProgress from "@mui/material/CircularProgress";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Farm, Field, SoilTextures } from "./models";
import { CropYear } from "./fieldactivities/models";
import AppContainer from "../common/AppContainer";
import HelpLabel from "../common/HelpLabel";
import MapInput from "../map/MapInput";
import TextField from "../common/TextField";
import Select from "../common/Select";
import Switch from "../common/Switch";
import WarningDialog from "../common/WarningDialog";
import ImportFieldDialog from "./ImportFieldDialog";
import ExportFieldDialog from "./ExportFieldDialog";
import HelpDialog from "./HelpDialog";
import * as navActions from "../common/actions";
import * as authActions from "../auth/actions";
import { apiFetch } from "../../api/fetch";
import { MAKE_OPTIONS } from "../../api/constants";
import { getValue, setValue } from "../../api/utils";
import area from "@turf/area";
import { dbFetch } from "../../api/fetch";
import distance from "@turf/distance";
import crop_name_lookup from "../dashboard/crop_name_lookup.json";

const allSoilTextures = SoilTextures.selectAll();
const getField = Field.selectByUrlId(field => {
    const cYs = field.cropyears.all().toRefArray();
    return {
        farm_name: field.farm.name,
        cropyears_loop: cYs,
        ...field.ref
    };
});
const newField = Farm.selectByUrlId(
    farm => ({
        farm_name: farm.name,
        farm: farm.id,
        isNew: true
    }),
    "farmid"
);

const styles = theme => ({
    pointer: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        "&:hover": {
            borderBottom: "8px solid lightgray"
        }
    },
    acresDiff: {
        "& input": {
            color: theme.palette.primary.main + " !important",
            border: "none",
            backgroundColor: "#efefef",
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    submitWrapper: {
        position: "relative",
        height: 40,
        marginBottom: 12
    },
    floatRight: {
        float: "right",
        marginRight: 16,
        height: 40
    },
    progressWrapper: {
        fontWeight: 700,
        marginTop: -32,
        marginLeft: 45
    },
    progress: {
        color: "#4CAF50",
        position: "absolute"
    },

    // Identification
    rightAlign: {
        textAlign: "right"
    },

    // Location
    grey: {
        backgroundColor: "#efefef",
        border: "12px solid #ffffff"
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px"
    },
    noMargin: {
        margin: 0,
        marginTop: 4
    },
    mapPadding: {
        paddingTop: "0 !important"
    },
    noPadding: {
        paddingLeft: "0 !important",
        paddingRight: "0 !important"
    },
    acresPadding: {
        paddingBottom: "2px !important",
        paddingTop: "0 !important"
    }
});

const HELP = {
    draw: (
        <div>
            Use the Draw Square or Polygon tools to outline the perimeter around the field to be evaluated. If it is an
            irregular (non-square or rectangle) shape, use the polygon tool to shape the boundary more specifically to
            your field.
            <br />
            <br />
            Single click along the boundary creating as series of points, then double-click to finalize the outline. You
            may edit points by single-clicking and moving to smooth out the boundary. The field has to be between .1 and
            9,999.99 acres.
        </div>
    ),
    size: (
        <div>
            Plantable Acres is the number of acres that could be planted on the field within a typical crop year.
            <br />
            <br />
            This acreage typically would exclude field approaches or conservation practices such as terraces or grassed
            waterways that are not farmed. The plantable acres can be adjusted up to 10% of the calculated acres from
            the field boundary drawn on the map.
        </div>
    ),
    override_soils: (
        <>
            The following soil properties are returned from the NRCS Soil Survey Geographic Database (SSURGO) based on
            the locaton of your field. You can override these properties. If you are satisfied with the field location
            and soil data, click Save Field below. You can then add a crop year for the field and enter associated
            Fieldprint Data needed to generate your Fieldprint Results.
        </>
    ),
    slope: (
        <div>
            Slope percent (%) is the steepness of a sloping area in your field. Slope percent impacts erosion on your
            field.
            <br />
            <br />
            We recommend determining the percent slope using a clinometer measuring the dominant slope acrossthe field.
            If do not have access to a clinometer, the information in the following table maybe be useful. First select
            the approximate field size (acres) and/or field width/length (feet), then identify the approximate elevation
            change (feet) over the field and the correlating slope percent.
            <br />
            <br />
            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td align="center">Number of acres in a square field</td>
                        <td align="center">Length and width of a square field (feet)</td>
                        <td align="center">Feet of elevation change for a 2% slope across the entire field</td>
                        <td align="center">Feet of elevation change for a 4% slope across the entire field</td>
                        <td align="center">Feet of elevation change for a 6% slope across the entire field</td>
                    </tr>
                    <tr>
                        <td align="center">640 =</td>
                        <td align="center">5,280</td>
                        <td align="center">106</td>
                        <td align="center">211</td>
                        <td align="center">317</td>
                    </tr>
                    <tr>
                        <td align="center">160 =</td>
                        <td align="center">2,640</td>
                        <td align="center">53</td>
                        <td align="center">106</td>
                        <td align="center">158</td>
                    </tr>
                    <tr>
                        <td align="center">40 =</td>
                        <td align="center">1,320</td>
                        <td align="center">26</td>
                        <td align="center">53</td>
                        <td align="center">79</td>
                    </tr>
                    <tr>
                        <td align="center">10 =</td>
                        <td align="center">660</td>
                        <td align="center">13</td>
                        <td align="center">26</td>
                        <td align="center">40</td>
                    </tr>
                </tbody>
            </table>
        </div>
    ),

    slope_length: (
        <div>
            The distance from the point of origin of overland flow to the point where either the slope gradient
            decreases enough that deposition begins, or the runoff water enters a well-defined channel that may be part
            of a drainage network or a constructed channel.
            <br />
            <br />
            Slope length should be determined based on the dominant field slope. Typically between 10 - 400 feet. When
            trying to gauge the length of a sloping area in your field the information in the following table may be
            useful. Use the field size (acres) to determine the length of the entire field (in feet).
            <br />
            <br />
            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td align="center" width="33%">
                            Number of acres in a square field
                        </td>
                        <td align="center" width="33%">
                            Length and width of a square field (Miles)
                        </td>
                        <td align="center" width="33%">
                            Length and width of a square field (Feet)
                        </td>
                    </tr>
                    <tr>
                        <td align="center">640 =</td>
                        <td align="center">1 =</td>
                        <td align="center">5,280</td>
                    </tr>
                    <tr>
                        <td align="center">160 =</td>
                        <td align="center">1/2 =</td>
                        <td align="center">2,640</td>
                    </tr>
                    <tr>
                        <td align="center">40 =</td>
                        <td align="center">1/4 =</td>
                        <td align="center">1,320</td>
                    </tr>
                    <tr>
                        <td align="center">10 =</td>
                        <td align="center">1/8 =</td>
                        <td align="center">660</td>
                    </tr>
                </tbody>
            </table>
        </div>
    ),
    soil_texture_id: (
        <div>
            Dominant surface soil texture class for the field boundary.
            <br />
            <br />
            <img alt="Soil Texture" style={{ width: 570 }} src={SoilTextureImg} />
        </div>
    ),
    omcontent: (
        <>
            The auto-filled data has been completed through the USDA-NRCS soil data from SURGO. Please, use soil test
            results to updated based on your soil test results.
        </>
    ),
    optional: (
        <>
            If desired, enter the Farm Serial Number, Tract Number and/or Field Number. These numbers are for your
            general reference and are not otherwise utilized in the system.
        </>
    )
};
const STEPS = [
    {
        selector: "#zoom-tools",
        position: "right",
        content:
            "Use these tools to find a specific location on the map. You can search by address or use your current location."
    },
    { selector: "#layers", position: "left", content: "Use the layer icon to turn available map layers on and off." },
    {
        selector: ".draw",
        position: "left",
        highlightedSelectors: [".draw", "#select_clu"],
        content:
            "There are two general options to create your field. You can select from existing USDA Common Land Units (CLUs) or use various drawing tools to draw the field from scratch."
    },
    { selector: "#select_clu", position: "left", content: "Select this icon to turn on the CLU layer." },
    {
        selector: "#apply_clu",
        position: "left",
        content: "Once you select one or more CLU field boundaries, click this icon to copy and edit your field."
    },
    {
        selector: ".draw",
        position: "left",
        content:
            "Use the various tools to draw your field from scratch or edit an existing field. You can add to or cut out parts of the field boundary."
    },
    {
        selector: ".load",
        position: "left",
        content: "Use these tools to delete a boundary, undo or redo actions, and import and export boundaries."
    },
    { selector: "#form-content", position: "right", content: "Enter details about your field." },
    {
        selector: ".field_size",
        position: "right",
        content: "If needed, adjust the number of acres that could be planted."
    },
    { selector: "fieldset", position: "right", content: "If desired, you can override the NRCS SSURGO data." },
    { selector: "button[type=submit]", position: "right", content: "Finally, save your field!" },
    { selector: "#help", position: "right", content: "View the help tour again at any time by clicking here!" }
];

const SQM_TO_ACRE = 0.00024711;
const KM_TO_MILE = 0.62137119;

const formatString = (template, values) => {
    return template.replace(/{(\w+)}/g, (match, key) => {
        return typeof values[key] !== 'undefined' ? values[key] : match;
    });
};

class LocationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingHistory: false,
            importDialog: false,
            helpDialog: false,
            importData: null,
            isDeveloped: false
        };
        this.mapInputRef = createRef();
    }

    // Form Submission
    updateField = values => {
        let { field } = this.props,
            fieldId = field.id;

        if (field.isNew) {
            delete values.isNew;
            fieldId = this.props.ormFieldCreate(values);

            // Reset field to original in case another field is being added from the same farm
            field = {
                id: field.farm,
                farm_name: field.farm_name,
                farm: field.farm,
                isNew: true,
                activeStep: 0
            };
            this.props.ormFarmUpdateLocalOnly(field);

            // Also manually set field values back - sometimes does not reset?
            this.props.field.name = null;
            this.props.field.extrainfo = null;
            this.props.field.activeStep = 0;

            this.props.handleUnsavedFields(false);
            this.props.navSelectPage("field_" + fieldId);
        } else {
            this.props.ormFieldUpdate({
                id: this.props.field.id,
                ...values
            });
            this.props.clearCropYears();
            this.props.handleUnsavedFields(false);
        }
        this.props.history.push(`/field/${fieldId}`);
    };

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        const validateAcres = acres => {
            if (!acres) return "Required";
            else if (acres < 0.1) return "Field size cannot be smaller than 0.1 acres";
            else if (acres > 10000) return "Field size cannot be larger than 10,000 acres";
            return null;
        };
        const validateDistance = distance => {
            if (!distance) return null;
            if (distance > 1) {
                return "Field boundaries are not allowed to be saved if there are two non-contiguous parts more than 1 mile apart.";
            }
            return null;
        };
        const validateDiff = acres => {
            if (acres > 10) return "The difference between planted and calculated acres cannot be greater than 10%";
            return null;
        };
        const slopeCheck = val => {
            if (!val && val !== 0) return "Required";
            const number = parseFloat(val);
            if (number < 0 || number > 100) return "Must be between 0-100";
            if ((number * 10) % 1 !== 0) return "Round to the nearest 1/10 percent";
            return null;
        };
        const slopeLengthCheck = val => {
            if (!val) return "Required";
            const number = parseFloat(val);
            if (number < 5 || number > 2600) return "Must be between 0-2600";
            if (number % 1 !== 0) return "Round to the nearest whole number";
            return null;
        };
        const omCheck = val => {
            if (!val && val !== 0) return "Required";
            const number = parseFloat(val);
            if (number < 0 || number > 100) return "Must be between 0-100";
            if ((number * 100) % 1 !== 0) return "Round to the nearest 1/100 percent";
            return null;
        };
        if (!values.geometry) {
            return { size_calc: "Draw a field boundary on the map" };
        }
        const validateCropHistory = val => {
            if (val && val === true) {
                return "Invalid land use detected, please redraw field boundary";
            }
        };
        return {
            name: isRequired(values.name),
            size:
                validateAcres(parseFloat(values.size)) ||
                validateDiff(parseFloat((values.diffAcres || "").split(" ")[0].replace("(", "").replace("%", ""))),
            size_calc:
                validateAcres(parseFloat(values.size_calc)) ||
                validateDistance(parseFloat(values.distance)) ||
                validateCropHistory(this.state.isDeveloped),
            soils: {
                slope: slopeCheck(values.soils?.slope),
                slope_length: slopeLengthCheck(values.soils?.slope_length)
            },
            greenhouse: {
                soil_texture_id: isRequired(values.greenhouse?.soil_texture_id)
            },
            waterquality: {
                omcontent: omCheck(values.waterquality?.omcontent)
            }
        };
    };

    componentDidMount() {
        if (this.props.authState?.user?.flags?.newFieldMap !== "read") {
            this.setState({ helpDialog: true });
        }
        const { handleUnsavedFields, field } = this.props;
        this.props.onRef(this.formApi);
        window.scrollTo(0, 0);
        // If the field is new we still have values in step one present
        if (!field.isNew) {
            setTimeout(function () {
                handleUnsavedFields(false);
            }, 100);
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    importClick = event => {
        this.setState({ importDialog: true });
    };

    importClose = () => {
        this.setState({ importDialog: false });
    };

    importSave = geojson => {
        this.setState({ importDialog: false, importData: geojson });
    };

    exportClick = event => {
        const { field } = this.props;
        const geojson = {
            type: "Feature",
            id: field.id,
            properties: {
                name: field.name,
                farm_name: field.farm_name,
                location: field.location,
                ...field.extrainfo
            },
            geometry: this.formApi.getValue("geometry")
        };
        this.setState({ exportGeometry: geojson });
    };

    exportClose = () => {
        this.setState({ exportGeometry: null });
    };

    fetchSoilProperties = async geometry => {
        const formApi = this.formApi;
        if (formApi.getValue("override_soils")) {
            return;
        }
        if (!geometry) {
            [
                "soils.slope",
                "soils.slope_length",
                "greenhouse.soil_texture_id",
                "waterquality.omcontent",
                "waterquality.k_factor",
                "waterquality.hsgroup"
            ].forEach(key => formApi.setValue(key, null));
            return;
        }
        this.setState({ loading: true });

        const response = await apiFetch("/v4/calc/Soils?format=json", {
            method: "POST",
            body: JSON.stringify({
                field: { geometry }
            })
        }),
            data = await response.json(),
            pct = (val, dec) => val.toFixed(dec) + " %";


        if (data.error) {
            this.setState({ helpOpen: true, helpText: "Unable to determine soil data" });
        } else {
            formApi.setValue("soils.slope", pct(data.slope.value, 1));
            formApi.setValue("soils.slope_length", data.slope_length.value + " " + data.slope_length.unit);
            formApi.setValue("greenhouse.soil_texture_id", data.texture_id);
            formApi.setValue("waterquality.omcontent", pct(data.om_content.value, 2));
            formApi.setValue("waterquality.k_factor", Math.round(data.k_factor * 100) / 100);
            formApi.setValue("waterquality.hsgroup", data.hs_group);
        }

        this.setState({ loading: false });
    };

    cropLookup = geometry => {
        var _this = this;
        const formApi = this.formApi;
        this.setState({ loadingHistory: true, error: null });

        dbFetch("/lookup/crop_history/geometry", {
            method: "POST",
            body: JSON.stringify({
                geometry: { geometry }
            })
            /*headers: {
                //Authorization: "Bearer " + apikey.apikey,
                "Content-Type": "application/json"
            }*/
        })
            .then(response => response.json())
            .then(data => {

                formApi.setValue("crop_history", data);
                _this.setState({ loadingHistory: false });

                //test for invalid land type. Only checking the most recent crop year
                var helpMessage = "";

                if (data && data.length > 0) {
                    let item = data[0];
                    if (item.crops.length) {
                        var crop_name = item.crops[0].crop;
                        let crop_name_lu = crop_name_lookup.find(item => item.CDL_NAME === crop_name);

                        // if it has a cdl message, display it in a popup
                        if (crop_name_lu && crop_name_lu.CDL_MESSAGE != "") {
                            helpMessage = formatString(crop_name_lu.CDL_MESSAGE, crop_name_lu);
                        }
                    }
                }

                if (helpMessage != "") {
                    this.setState({
                        helpOpen: true,
                        helpText: helpMessage,
                        isDeveloped: true
                    });
                    if (this.mapInputRef.current) {
                        this.mapInputRef.current.clearDrawObjects();
                        this.mapInputRef.current.clearSelectedFeaturesInCLULayer();
                    }
                } else {
                    this.setState({
                        isDeveloped: false
                    });
                }

            })
            .catch(error => {
                _this.setState({ loadingHistory: false, error: error });
            });
    };

    // Calculate acres difference between planted (size) and calculated (size_calc)
    // When Planted Acres is changed manually
    handleAcresChange = (value, value2) => {
        if (!value) {
            value = this.formApi.getValue("size");
        }
        if (!value2) {
            value2 = this.formApi.getValue("size_calc");
        }
        if (value) {
            let planted = parseFloat(value);
            let calculated = parseFloat(value2);
            let difference = Math.abs((((planted - calculated) / planted) * 100).toFixed(2));
            this.formApi.setValue("diffAcres", "(" + difference + "% Diff.)");
        }
    };

    warnSoil = (input, value) => {
        if (input === "slope") {
            if (parseFloat(value) > 20) {
                this.setState({ helpOpen: true, helpText: "Typically slope does not exceed 20%." });
            }
        } else if (input === "slopelength") {
            if (parseFloat(value) > 1410) {
                this.setState({ helpOpen: true, helpText: "Typically slope does not exceed 1410 ft." });
            }
        }
    };

    render() {
        const { classes, field, soilTextures, handleUnsavedFields, authState, authSetFlag } = this.props;
        const { loading, loadingHistory, importDialog, helpDialog, importData, exportGeometry, helpOpen, helpText } =
            this.state;
        const isOverride = formApi => formApi.getValue("override_soils"),
            updateGeometry = (formApi, value) => {
                if (
                    JSON.stringify(formApi.getValue("geometry")) === JSON.stringify(value) &&
                    (formApi.getValue("size_calc") || !value)
                ) {
                    return;
                }
                const sizeCalc = value ? (area(value) * SQM_TO_ACRE).toFixed(2) : null;
                formApi.setValue("geometry", value);
                if (
                    !formApi.getValue("size") ||
                    parseFloat(formApi.getValue("size")) === parseFloat(formApi.getValue("size_calc")) ||
                    !sizeCalc
                ) {
                    formApi.setValue("size", sizeCalc);
                }
                formApi.setValue("size_calc", sizeCalc);
                formApi.setValue("distance", value ? findMaxMinDistance(value) * KM_TO_MILE : null);
                this.handleAcresChange(null, sizeCalc);
                this.fetchSoilProperties(value);
                this.cropLookup(value);
            },
            handleAction = action => {
                if (action === "import") {
                    this.importClick();
                } else if (action === "export") {
                    this.exportClick();
                } else if (action === "help") {
                    this.setState({ helpDialog: true });
                }
            },
            handleImported = () => {
                this.setState({ importData: null });
            },
            closeHelp = () => {
                this.setState({ helpDialog: false });
            };

        return (
            <>
                <ExportFieldDialog open={!!exportGeometry} geometry={exportGeometry} onClose={this.exportClose} />
                <ImportFieldDialog open={importDialog} onClose={this.importClose} onSave={this.importSave} />
                <HelpDialog open={helpDialog} onClose={closeHelp} authState={authState} authSetFlag={authSetFlag} />
                <WarningDialog
                    noActions
                    open={helpOpen}
                    onClose={() => this.setState({ helpOpen: false })}
                    title="Warning"
                    text={helpText}
                />

                <Form
                    getApi={el => (this.formApi = el)}
                    key={field.id}
                    dontValidateOnMount="true"
                    defaultValues={field}
                    formDidUpdate={() => handleUnsavedFields(true)}
                    validateError={this.errorValidator}
                    onSubmit={this.updateField}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm} style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                            <div
                                id="form-content"
                                style={{
                                    width: "24em",
                                    display: "flex",
                                    flexDirection: "column",
                                    boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.1)"
                                }}>
                                <div
                                    style={{
                                        flex: 1,
                                        padding: "1em",
                                        overflowY: "auto"
                                    }}>
                                    <input type="hidden" field="geometry" id="geometry" />
                                    <TextField
                                        field="name"
                                        label="Field Name"
                                        fullWidth
                                        help="Enter the name of your field. Use a name you will recognize."
                                        margin="normal"
                                    />
                                    <TextField
                                        field="fpp_id"
                                        label="FPP ID"
                                        fullWidth
                                        margin="normal"
                                        disabled
                                        help="Identifier for use with import tools"
                                    />
                                    <Divider />
                                    <TextField
                                        disabled
                                        field="size_calc"
                                        label="Calculated Acres"
                                        units="ac"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        className="field_size"
                                        type="number"
                                        inputProps={{ min: 0.01, max: 10000.0, step: 0.01 }}
                                        field="size"
                                        label="Plantable Acres"
                                        units="ac"
                                        eventHandle={this.handleAcresChange}
                                        disabled={!formApi.getValue("size_calc")}
                                        margin="normal"
                                        fullWidth
                                        help={HELP.size}
                                    />
                                    <input type="hidden" field="diffAcres" />
                                    <input type="hidden" field="waterquality.k_factor" />
                                    <input type="hidden" field="waterquality.hsgroup" />
                                    <Switch
                                        field="override_soils"
                                        labelYes="Override"
                                        labelNo="Use Defaults"
                                        label={
                                            <HelpLabel
                                                inputLabel="Override USDA soil data?"
                                                helpText={HELP.override_soils}
                                            />
                                        }
                                    />
                                    <TextField
                                        type="number"
                                        field="soils.slope"
                                        label="Slope"
                                        units="%"
                                        disabled={!isOverride(formApi)}
                                        eventHandle={value => this.warnSoil("slope", value)}
                                        help={HELP.slope}
                                        inputProps={{ min: 0.0, max: 100.0, step: 0.1 }}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        type="number"
                                        field="soils.slope_length"
                                        label="Slope Length"
                                        units="ft"
                                        disabled={!isOverride(formApi)}
                                        eventHandle={value => this.warnSoil("slopelength", value)}
                                        help={HELP.slope_length}
                                        inputProps={{ min: 5, max: 2600 }}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Select
                                        field="greenhouse.soil_texture_id"
                                        label="Surface Soil Texture"
                                        options={MAKE_OPTIONS(soilTextures)}
                                        disabled={!isOverride(formApi)}
                                        help={HELP.soil_texture_id}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        type="number"
                                        field="waterquality.omcontent"
                                        label="Organic Matter Content"
                                        disabled={!isOverride(formApi)}
                                        help={HELP.omcontent}
                                        units="%"
                                        inputProps={{ min: 0.0, max: 100.0, step: 0.01 }}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Accordion elevation={0} disableGutters>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: 0 }}>
                                            <Typography>Optional Field Details</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            style={{
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                paddingTop: 0,
                                                paddingBottom: "1em"
                                            }}>
                                            <TextField
                                                field="extrainfo.farm_serial_number"
                                                label="Farm Serial Number"
                                                fullWidth
                                                margin="normal"
                                                help={HELP.optional}
                                            />
                                            <TextField
                                                field="extrainfo.tract_number"
                                                label="Tract Number"
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                field="extrainfo.field_number"
                                                label="Field Number"
                                                fullWidth
                                                margin="normal"
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div style={{ borderTop: "1px solid lightgray", padding: "1em" }}>
                                    <div className={classes.submitWrapper}>
                                        <Button
                                            fullWidth
                                            variant="raised"
                                            type="submit"
                                            color="primary"
                                            disabled={loading || loadingHistory}>
                                            Save Field
                                        </Button>
                                        {(loading || loadingHistory) && (
                                            <Typography className={classes.progressWrapper}>
                                                Calculating Soil Data Please Wait...{" "}
                                                <CircularProgress size={24} className={classes.progress} />
                                            </Typography>
                                        )}
                                    </div>
                                    <Button
                                        fullWidth
                                        variant="raised"
                                        component={Link}
                                        to={field.isNew ? `/farm/${field.farm}/edit` : `/field/${field.id}`}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                            <MapInput
                                ref={this.mapInputRef}
                                initialValue={field.geometry}
                                onChange={value => updateGeometry(formApi, value)}
                                onAction={handleAction}
                                importData={importData}
                                onImported={handleImported}
                            />
                        </form>
                    )}
                </Form>

            </>
        );
    }
}

class FieldProperties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unsavedFields: false
        };
    }

    handleUnsavedFields = unsavedFields => {
        this.setState({ unsavedFields });
    };

    clearCropYears = () => {
        const { cropyears_loop } = this.props.field;
        const { ormCropYearUpdate } = this.props;
        cropyears_loop.forEach(function (cY) {
            setValue(cY, "metrics", null);
            ormCropYearUpdate(cY);
        });
    };

    updateParent = () => {
        this.setState({ foo: "bar" });
    };

    componentDidMount() {
        /*this.props.field.name = null;
        this.props.field.extrainfo = null;
        this.props.field.activeStep = 0;*/
        this.loadField({});
    }

    componentDidUpdate(prevProps) {
        this.loadField(prevProps);
    }

    componentWillUnmount() {
        /*this.props.field.name = null;
        this.props.field.extrainfo = null;
        this.props.field.activeStep = 0;*/
    }

    loadField(prevProps) {
        const { field, ormFieldLoadDetail, syncState, authState } = this.props,
            { field: lastYear, syncState: lastSyncState } = prevProps;

        if (!field || field.isNew || (authState.user && authState.user.guest)) {
            return;
        }
        if (!lastYear || lastYear.id !== field.id || !lastSyncState.ready) {
            if (syncState.ready && !getValue(field, "synced")) {
                ormFieldLoadDetail(field.id);
            }
        }
    }

    render() {
        const {
            classes,
            field,
            soilTextures,
            ormFieldUpdate,
            ormFieldCreate,
            ormFieldUpdateLocalOnly,
            ormFarmUpdateLocalOnly,
            history,
            navSelectPage,
            authSetFlag,
            authState
        } = this.props;
        const { unsavedFields, navOpen } = this.state;

        var synced;
        if (field.isNew || (authState.user && authState.user.guest)) synced = true;
        else synced = getValue(field, "synced");
        return (
            <AppContainer
                authenticated
                flex
                synced={!synced}
                handleUnsavedFields={this.handleUnsavedFields}
                color="field"
                title={(field.name || "New Field") + " on " + (field.farm_name || "New Farm")}
                navOpen={navOpen || false}
                onNavOpenChange={navOpen => this.setState({ navOpen })}>
                <Prompt
                    when={unsavedFields}
                    message="You have entered data that has not been saved in our system yet. Do you want to leave without finishing?"
                />

                <TourProvider steps={STEPS}>
                    <LocationForm
                        onRef={el => (this.activeForm = el)}
                        field={field}
                        soilTextures={soilTextures}
                        classes={classes}
                        ormFieldUpdateLocalOnly={ormFieldUpdateLocalOnly}
                        ormFarmUpdateLocalOnly={ormFarmUpdateLocalOnly}
                        ormFieldUpdate={ormFieldUpdate}
                        ormFieldCreate={ormFieldCreate}
                        handleUnsavedFields={this.handleUnsavedFields}
                        navSelectPage={navSelectPage}
                        authSetFlag={authSetFlag}
                        authState={authState}
                        history={history}
                        clearCropYears={this.clearCropYears}
                        updateParent={this.updateParent}
                    />
                </TourProvider>
            </AppContainer>
        );
    }
}

FieldProperties = connect(
    (state, ownProps) => ({
        field:
            ownProps.match && ownProps.match.params && ownProps.match.params.id
                ? getField(state, ownProps)
                : newField(state, ownProps),
        soilTextures: allSoilTextures(state),
        syncState: state.sync,
        authState: state.auth
    }),
    {
        ...Field.actions,
        ...Farm.actions,
        ...CropYear.actions,
        ...navActions,
        ...authActions
    }
)(FieldProperties);

export default withStyles(styles)(withRouter(FieldProperties));

function findMaxMinDistance(geometry) {
    if (!geometry || geometry.type !== "MultiPolygon") {
        return 0;
    }

    let maxMinDistance = 0;
    for (const poly1 of geometry.coordinates) {
        for (const poly2 of geometry.coordinates) {
            if (poly1 === poly2) {
                continue;
            }
            let minDistance = 1000000;
            for (const coord1 of poly1[0]) {
                for (const coord2 of poly2[0]) {
                    minDistance = Math.min(minDistance, distance(coord1, coord2));
                }
            }
            maxMinDistance = Math.max(maxMinDistance, minDistance);
        }
    }
    return maxMinDistance;
}
