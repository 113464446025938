import { FormControlLabel, Grid, ListSubheader, MenuItem, Radio, Typography } from "@mui/material";
import TextField from "../common/TextField";
import Select from "../common/Select";
import RadioGroup from "../common/RadioGroup";
import ActivityDetail from "../ActivityDetail";
import React, { useState } from "react";
import { Agriculture } from "@mui/icons-material";

export default function Harvest({ activity }) {
    // menuitem style
    const mi = {
        paddingLeft: "32px"
    };

    // subheader style
    const sh = {
        fontWeight: "bold",
        fontSize: "1.15em",
        lineHeight: 2
    };

    const [values, setValues] = useState({ ...activity });

    const handleSubmit = data => {
        console.log(data);
        setValues(data);
    };

    return (
        <ActivityDetail
            summary={
                <>
                    <Grid item sm={4} xs={12}>
                        <Typography style={{ fontSize: "14px" }}>YIELD</Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>{values.yield} bu/ac</Typography>
                    </Grid>
                </>
            }
            values={values}
            icon={<Agriculture />}
            type="Harvest"
            onSubmit={handleSubmit}>
            <Grid container paddingTop={2} spacing={1}>
                <Grid item sm={5} xs={12}>
                    <Typography>Activity Date</Typography>
                    <TextField
                        field="date"
                        type="date"
                        size="small"
                        sx={{ width: "180px" }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item sm={7} xs={12}>
                    <Typography>Operation</Typography>
                    <Select size="small" field="operation" sx={{ width: "280px" }} fullWidth>
                        <MenuItem value={1}>Knife, windrow, combine</MenuItem>
                        <MenuItem value={2}>Hay, grass or legume</MenuItem>
                        <MenuItem value={3}>Hay, grass or legume, kill crop</MenuItem>
                        <MenuItem value={4}>Killing crop 20pct standing stubble</MenuItem>
                        <MenuItem value={5}>Silage</MenuItem>
                        <MenuItem value={6}>Cotton and shred</MenuItem>
                        <MenuItem value={7}>Shallow root crops, residue surface</MenuItem>
                        <MenuItem value={8}>Deep root crops, residue buried</MenuItem>
                    </Select>
                </Grid>
                <Grid item sm={5} xs={12}>
                    <Typography>Yield</Typography>
                    <TextField
                        size="small"
                        fullWidth
                        field="yield"
                        units="units"
                        type="number"
                        inputProps={{
                            step: 1
                        }}
                        sx={{ width: "140px" }}
                    />
                </Grid>
                <Grid item sm={7} xs={12}>
                    <Typography>Irrigation Water Applied</Typography>
                    <TextField
                        size="small"
                        type="number"
                        inputProps={{
                            step: 1
                        }}
                        sx={{ width: "140px" }}
                        field="irrigationWater"
                        variant="outlined"
                        units="in / ac"
                    />
                </Grid>
            </Grid>
            <Grid container paddingTop={2} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Transportation</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Distance from point to sale</Typography>
                    <TextField
                        size="small"
                        type="number"
                        inputProps={{
                            step: 1
                        }}
                        sx={{ width: "140px" }}
                        field="distance"
                        variant="outlined"
                        units="miles"
                    />
                </Grid>
            </Grid>
            <Grid container paddingTop={2} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Drying</Typography>
                </Grid>
                <Grid item sm={5} xs={12}>
                    <Typography>Was crop dried?</Typography>
                    <RadioGroup row field="crop_dried">
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
                <Grid item sm={7} xs={12}>
                    <Typography>Do you own drying equipment and dry onsite? Or is drying done externally?</Typography>
                    <RadioGroup row field="dry_location">
                        <FormControlLabel value="onsite" control={<Radio />} label="Onsite" />
                        <FormControlLabel value="offsite" control={<Radio />} label="Offsite" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Drying system</Typography>
                    <Select size="small" field="dry_system" sx={{ width: "475px" }} fullWidth>
                        <ListSubheader style={sh}>In bin</ListSubheader>
                        <MenuItem style={mi} value={1}>
                            Combination high temperature and air
                        </MenuItem>
                        <MenuItem style={mi} value={2}>
                            Natural air/air only
                        </MenuItem>
                        <MenuItem style={mi} value={3}>
                            Low temperature
                        </MenuItem>
                        <MenuItem style={mi} value={4}>
                            High temperature-air recirculation - continuous flow/mixed flow
                        </MenuItem>
                        <MenuItem style={mi} value={5}>
                            High temperature layered batch-in-bin or bin with stirring device
                        </MenuItem>
                        <ListSubheader style={sh}>Batch Dryer</ListSubheader>
                        <MenuItem style={mi} value={6}>
                            High temperature-no air recirculation (Continuous flow dryer)
                        </MenuItem>
                        <MenuItem style={mi} value={7}>
                            High temperature-no air recirculation (Automatic batch dryer)
                        </MenuItem>
                        <MenuItem style={mi} value={8}>
                            PTO type - High temperature-no air recirculation
                        </MenuItem>
                        <ListSubheader style={sh}>Other Options</ListSubheader>
                        <MenuItem style={mi} value={9}>
                            No Drying System Used
                        </MenuItem>
                        <MenuItem style={mi} value={10}>
                            Commercially dried by buyer
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Points of moisture removed</Typography>
                    <TextField
                        size="small"
                        type="number"
                        inputProps={{
                            step: 1
                        }}
                        field="moisture_removed"
                        variant="outlined"
                        units="%"
                        sx={{ width: "100px" }}
                    />
                </Grid>
            </Grid>
        </ActivityDetail>
    );
}
