import Map from "react-map-gl/maplibre";
import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import "maplibre-gl/dist/maplibre-gl.css";
import Geocoder from "./Geocoder";
import { useTour } from "@reactour/tour";
import useGeolocation from "./geolocate";
import useHistory from "./history";
import { ZoomTools, EditTools, ACTION, MODE, FLAG, DRAW_MODES } from "./Tools";
import LayerList, { SoilsLayer, CLULayer } from "./LayerList";
import Draw, { reduceFeatures } from "./Draw";
import bbox from "@turf/bbox";

const MapInput = forwardRef(({ initialValue, onChange, onAction, importData, onImported }, ref) => {
    const [mode, setMode] = useState(null),
        mapRef = useRef(),
        drawControlRef = useRef(),
        cluLayerRef = useRef(),
        [data, setData, undo, redo] = useHistory(initialValue),
        [flags_, setFlags] = useState({}),
        { steps, currentStep, isOpen: isTourOpen } = useTour(),
        flags = isTourOpen
            ? { ...flags_, select_clu: ["#apply_clu", "#select_clu"].includes(steps[currentStep].selector) }
            : flags_,
        [layers, setLayers] = useState({ imagery: true }),
        [selected, setSelected] = useState(null),
        [viewState, setViewState] = useState({
            longitude: -96,
            latitude: 39,
            zoom: 3.5
        }),
        [startLocate, stopLocate] = useGeolocation(setViewState, () => toggleFlag("locate")),

        onSetBounds = (bounds, padding) => {
            const camera = mapRef.current.cameraForBounds(bounds, {
                maxZoom: 16,
                padding: padding || 0
            });
            setViewState({
                latitude: camera.center.lat,
                longitude: camera.center.lng,
                zoom: camera.zoom
            });
        },

        activateTool = tool => {
            if (tool.type === ACTION) {
                triggerAction(tool.id);
            } else if (tool.type === MODE) {
                if (mode === tool.id) {
                    setMode("simple_select");
                    if (flags.cut) {
                        toggleFlag("cut");
                    }
                } else {
                    setMode(tool.id);
                }
            } else if (tool.type === FLAG) {
                toggleFlag(tool.id);
            }
        },

        isActive = tool => {
            if (tool.type === MODE && mode === tool.id) {
                return true;
            } else if (tool.type === FLAG && flags[tool.id]) {
                return true;
            } else {
                return false;
            }
        },

        isVisible = tool => {
            if (
                flags.select_clu &&
                (tool.type === MODE || ["cut", "undo", "redo", "delete", "import", "export"].includes(tool.id))
            ) {
                return false;
            } else if (!flags.select_clu && tool.id === "apply_clu") {
                return false;
            } else {
                return true;
            }
        },

        isDisabled = tool => {
            if (tool.id === "undo" && !undo) {
                return true;
            } else if (tool.id === "redo" && !redo) {
                return true;
            } else if ((tool.id === "export" || tool.id === "zoomfit") && (!data || data.features.length === 0)) {
                return true;
            } else if (tool.id === "select_clu" && mapRef.current && mapRef.current.getZoom() < 11) {
                return true;
            } else if (tool.id === "apply_clu" && (!selected || !flags.select_clu)) {
                return true;
            } else {
                return false;
            }
        },

        isLayerDisabled = layer => {
            if (layer.id.startsWith("soils") && (flags.select_clu || mapRef.current.getZoom() < 12)) {
                return true;
            } else {
                return false;
            }
        },

        triggerAction = (action, noFlag) => {
            switch (action) {
                case "zoomin": {
                    mapRef.current.zoomIn();
                    break;
                }
                case "zoomout": {
                    mapRef.current.zoomOut();
                    break;
                }
                case "zoomfit": {
                    onSetBounds(bbox(data), 48);
                    break;
                }
                case "apply_clu": {
                    if (selected) {
                        setData(reduceFeatures({ ...data, features: data.features.concat(selected.features) }));
                        setSelected(null);
                        if (flags.select_clu && !noFlag) {
                            toggleFlag("select_clu", true);
                        }
                    }
                    break;
                }
                case "delete": {
                    setMode("delete");
                    break;
                }
                case "undo": {
                    if (undo) {
                        undo();
                    }
                    break;
                }
                case "redo": {
                    if (redo) {
                        redo();
                    }
                    break;
                }
                default: {
                    onAction(action);
                }
            }
        },

        toggleFlag = (id, noAction) => {
            const nextState = !flags[id];
            setFlags({ ...flags, [id]: nextState });
            if (id === "cut" && nextState && !DRAW_MODES.includes(mode)) {
                setMode("draw_polygon");
            }
            if (id === "select_clu" && selected && !noAction) {
                if (window.confirm("Copy selected CLU polygon to field?")) {
                    triggerAction("apply_clu", true);
                } else {
                    setSelected(null);
                }
            }
            if (id === "locate") {
                if (nextState) {
                    startLocate();
                } else {
                    stopLocate();
                }
            }
        },

        toggleLayer = id => {
            const nextState = !layers[id],
                nextLayers = { ...layers, [id]: nextState };
            setLayers(nextLayers);
        };

    const clearDrawObjects = () => {
        if (drawControlRef.current) {
            const drawControl = drawControlRef.current.getDrawControl();
            if (drawControl) {
                const allFeatures = drawControl.getAll().features;
                allFeatures.forEach(feature => drawControl.delete(feature.id));
            }
        }
    };

    const clearSelectedFeaturesInCLULayer = () => {
        if (cluLayerRef.current) {
            cluLayerRef.current.clearSelectedFeatures();
            setSelected(null);
            setData({
                type: "FeatureCollection",
                features: []
            });
        }
    };

    useImperativeHandle(ref, () => ({
        clearDrawObjects,
        clearSelectedFeaturesInCLULayer
    }));

    useEffect(() => {
        if (mapRef.current && drawControlRef.current) {
            const mapInstance = mapRef.current.getMap();
            const drawControl = drawControlRef.current.getDrawControl();
            mapInstance.addControl(drawControl);
            drawControlRef.current = drawControl;
        }
        return () => {
            if (mapRef.current && drawControlRef.current) {
                const mapInstance = mapRef.current.getMap();
                mapInstance.removeControl(drawControlRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (data && data.features.length > 0) {
            onChange(data.features[0].geometry);
        } else {
            onChange(null);
        }
    }, [data, onChange]);

    useEffect(() => {
        if (initialValue && mapRef.current) {
            onSetBounds(bbox(initialValue), 48);
        }
    }, [mapRef.current, initialValue]); // eslint-disable-line

    useEffect(() => {
        if (importData) {
            setData({
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        id: 1,
                        geometry: importData
                    }
                ]
            });
            onImported();
        }
    }, [importData, onImported, setData]);

    return (
        <div
            style={{
                flex: 1,
                position: "relative"
            }}>
            <Toolbar id="zoom-tools" left={16} paddingTop={8} paddingBottom={8} paddingLeft={4} paddingRight={4}>
                <ZoomTools
                    activateTool={activateTool}
                    isActive={isActive}
                    isDisabled={isDisabled}
                    isVisible={isVisible}
                />
            </Toolbar>
            <Toolbar id="edit-tools" right={16} paddingTop={8} paddingBottom={8} paddingLeft={4} paddingRight={4}>
                <EditTools
                    activateTool={activateTool}
                    isActive={isActive}
                    isDisabled={isDisabled}
                    isVisible={isVisible}
                />
            </Toolbar>
            {flags.search && (
                <Toolbar left={80} width="20em" padding={8} overflowY="hidden">
                    <Geocoder onSetBounds={onSetBounds} />
                </Toolbar>
            )}
            {flags.layers && (
                <Toolbar right={80} width="15em">
                    <LayerList layers={layers} toggleLayer={toggleLayer} isDisabled={isLayerDisabled} />
                </Toolbar>
            )}
            <Map
                ref={mapRef}
                {...viewState}
                dragRotate={false}
                onMove={evt => setViewState(evt.viewState)}
                style={{ width: "100%", height: "100%" }}
                mapStyle={layers.imagery ? "/static/app/style-hybrid.json" : "/static/app/style-blank.json"}>
                <Draw
                    ref={drawControlRef}
                    mode={mode}
                    onSetMode={setMode}
                    data={data}
                    onSetData={setData}
                    flags={flags}
                    onToggleFlag={toggleFlag}
                />
                <SoilsLayer layers={layers} flags={flags} />
                <CLULayer layers={layers} flags={flags} selected={selected} setSelected={setSelected} ref={cluLayerRef} />
            </Map>
        </div>
    );
});

MapInput.displayName = 'MapInput';

export default MapInput;

function Toolbar({ id, children, ...style }) {
    return (
        <div
            id={id}
            style={{
                position: "absolute",
                top: 16,
                width: 40,
                zIndex: 1000,
                backgroundColor: "white",
                border: "1px solid #999",
                maxHeight: "calc(100% - 32px)",
                overflowX: "hidden",
                overflowY: "auto",
                ...style
            }}>
            {children}
        </div>
    );
}
